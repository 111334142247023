<template>
  <section class="wrapper" id="block-14-1">
    <div class="divider-horizontal mt-0"></div>
    <div class="tail">
      <img
        src="@/assets/images/onto-blog-background.png"
        alt="blog background"
        class="background"
      />
      <div class="tail-content">
        <img
          src="@/assets/icons/icon-red-onto.png"
          style="width: 36px; height: 36px"
          alt="onto-icon"
        />
        <div class="description">
          <h3 class="tail-title">блог онтосеть запущен!</h3>
          <p class="tile-text">
            Новости, статьи и обновления – все в одном месте. Узнайте, как
            работают процессы и подходы изнутри на основе актуальной информации
            о работе консорциума.
          </p>
        </div>
        <div class="link-container">
          <a
            href="https://ontonet.org/"
            class="tile-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="link">ontonet.org</span>
            <img src="@/assets/icons/icon-go-to-white.png" alt="go-to" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockSection14-1",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.tail {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 20px;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.tail-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  max-width: 1200px;
  width: 100%;
  margin: 0 20px;
  padding: 40px;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 35px;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 50%;
}

.tail-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.tile-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: none;
}

.tile-link {
  display: flex;
  align-items: center;
  border-radius: 170px;
  text-decoration: none;
  font-family: "Adieu Light", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}

.link {
  height: 53px;
  text-align: center;
  border: 1px solid #ffff;
  border-radius: 170px;
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
  align-items: center;
}

.tile-link img {
  width: 53px;
  height: 53px;
}

.link-container {
  display: flex;
  align-items: end;
  justify-content: end;
  height: 100%;
  flex-grow: 1;
}
</style>
