<template>
  <section class="wrapper" id="block-11">
    <div class="content-block">
      <h4 class="section-title outlined-white mb-4">ПРОБЛЕМА №2</h4>
      <h3 class="section-sub-title mb-15">проблема роста тсо</h3>
      <h2 class="title">
        TCO - совокупная <br />
        стоимость владения<br />
        цифровым решением
      </h2>
      <div
        class="divider-vertical"
        style="height: 115px; margin: 150px 0"
      ></div>
      <h3 class="sub-title">
        Рост TCO ставит под<br />
        <span class="outlined-white">сомнение</span>
        разработку<br />
        цифровых решений для<br />
        малого и среднего<br />
        бизнеса
      </h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock11",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content-block {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.section-sub-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.sub-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
