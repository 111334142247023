<template>
  <section class="wrapper" ref="blockSection" id="hero-animation">
    <img
      src="@/assets/images/block-hero-background.png"
      alt="background"
      class="sky"
    />
    <div class="earth">
      <div class="orb-1"></div>
      <div class="orb-2"></div>
      <div class="orb-3"></div>
      <div class="orb-4"></div>
      <div class="orb-5"></div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { initLocoScroll } from "@/main";

export default {
  name: "HeroAnimation",
  data() {
    return {
      isAtStart: true,
    };
  },
  methods: {
    handleScroll(event) {
      if (this.isAtStart && event.deltaY < 0) {
        this.$emit("revertVideo");
      }
    },
  },
  mounted() {
    setTimeout(() => initLocoScroll(), 500);

    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    gsap.fromTo(
      blockSection.querySelector(".earth"),
      { autoAlpha: 0 },
      {
        delay: 0.8,
        autoAlpha: 1,
        duration: 1,
        ease: "linear",
      }
    );

    gsap.fromTo(
      blockSection,
      { autoAlpha: 0 },
      {
        delay: 1.3,
        autoAlpha: 1,
        duration: 0.7,
        ease: "linear",
      }
    );

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top top",
        end: "+=100%",
        pin: true,
        scrub: 3,
        onEnter: () => {
          window.locoScroll.update();
          this.isAtStart = false;
        },
        onLeave: () => {
          window.locoScroll.update();
        },
        onUpdate: (self) => {
          self.direction < 0 && self.progress < 0.00001
            ? (this.isAtStart = true)
            : (this.isAtStart = false);
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".orb-1"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 }
    );

    timeline.fromTo(
      blockSection.querySelector(".orb-2"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 }
    );

    timeline.fromTo(
      blockSection.querySelector(".orb-3"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 }
    );

    timeline.fromTo(
      blockSection.querySelector(".orb-4"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 }
    );

    timeline.fromTo(
      blockSection.querySelector(".orb-5"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 }
    );

    window.addEventListener("wheel", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("wheel", this.handleScroll);
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  z-index: 1;
  height: auto;
  width: 100vw;
  overflow: hidden;
}

.sky {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
}

.earth {
  position: absolute;
  z-index: 3;
  top: -5%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: 100vw;
  background-image: url("@/assets/images/earth.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0;
}

.orb-1 {
  position: absolute;
  z-index: 4;
  top: 13%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: 100vw;
  background-image: url("@/assets/images/orb1.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0;
}

.orb-2 {
  position: absolute;
  z-index: 4;
  top: 13%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: 100vw;
  background-image: url("@/assets/images/orb2.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0;
}

.orb-3 {
  position: absolute;
  z-index: 4;
  top: 13%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: 100vw;
  background-image: url("@/assets/images/orb3.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0;
}

.orb-4 {
  position: absolute;
  z-index: 4;
  top: 14%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: 100vw;
  background-image: url("@/assets/images/orb4.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0;
}

.orb-5 {
  position: absolute;
  z-index: 4;
  top: 16%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: 100vw;
  background-image: url("@/assets/images/orb5.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0;
}
</style>
