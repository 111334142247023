<template>
  <section class="wrapper" id="block-22">
    <div class="content-block">
      <h4 class="section-title outlined-white mb-15">ПРОБЛЕМА №4</h4>
      <h2 class="title">
        Остановка<br />
        бизнес<br />-процессов при<br />
        повреждении<br />
        цифровой<br />
        инфраструктуры.
      </h2>
      <div class="text-block">
        <h3 class="text">
          Бизнес - бег с препятствиями. Время - ключевой фактор победы или
          поражения. Конкуренты стремятся замедлить ваш прогресс, нарушив
          непрерывность работы вашего бизнеса.
        </h3>
      </div>
    </div>
    <div class="content-row">
      <div class="description">
        <p class="text">
          Остановка бизнеса приводит к потере денег, ущербу репутации,
          отставанию от конкурентов, выбыванию из гонки и даже полной потере
          бизнеса.
        </p>
        <p class="text my-2">
          Обеспечение непрерывности бизнеса - ключевое поле битвы в условиях
          современных конкурентных сражений.
        </p>
        <p class="text">
          Сегодня, в эпоху интернета и облачных технологий, угроза повреждения
          критической цифровой инфраструктуры поставщика услуг<br />
          — <span class="outlined-white">ключевой вызов</span>
          для любой компании.
        </p>
      </div>
      <img
        src="@/assets/images/image-problem-block-22.png"
        alt="sparc"
        class="illustration"
      />
      <img
        src="@/assets/images/background-problem-block-22.png"
        alt="blur"
        class="background"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock22",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content-block {
  max-width: 1050px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.text-block {
  max-width: 650px;
  width: 100%;
  padding: 0 20px;
  margin-top: 65px;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: none;
}

.content-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  max-width: 1000px;
  width: 100%;
  margin-top: 150px;
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 50%;
}

.description .text {
  text-align: start;
}

.illustration {
  flex: 1;
  max-width: 260px;
  object-fit: contain;
}

.background {
  position: absolute;
  max-width: 720px;
  width: 100%;
  right: -20%;
  top: -60%;
}
</style>
