<template>
  <div class="page">
    <ContactForm />
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import eventBus from "@/utils/eventsBus";

export default {
  name: "ContactsView",
  components: { ContactForm },
  mounted() {
    eventBus.emit("loadingTrigger", true);
    setTimeout(() => window.locoScroll.stop(), 600);
  },
};
</script>

<style scoped></style>
