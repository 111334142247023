<template>
  <div>
    <HeroAnimation @revertVideo="() => this.$emit('revert')" />
    <BlockSection1 />
    <BlockSection2 />
    <div class="divider-horizontal mb-0"></div>
    <BlockSection3 />
    <BlockSection4 />
    <div class="divider-horizontal"></div>
    <BlockSection5 />
    <BlockSection6 />
    <div class="divider-horizontal"></div>
    <BlockSection7 />
    <BlockSection8 />
    <BlockSection9 />
    <div class="divider-horizontal mt-0"></div>
    <BlockSection10 />
    <BlockSection11 />
    <BlockSection12 />
    <BlockSection13 />
    <BlockSection14 />
    <BlockSection14_1 />
    <div class="divider-horizontal"></div>
    <BlockSection15 />
    <BlockSection16 @showProblemModal="openProblemModal" />
    <BlockSection17 />
    <div class="divider-horizontal"></div>
    <BlockSection18 />
    <div class="divider-horizontal"></div>
    <BlockSection19 />
    <BlockSection20 />
    <div class="divider-horizontal"></div>
    <BlockSection21 @showAuthorModal="openModalAuthor" />
    <BlockSection22 />
  </div>
</template>

<script>
import BlockSection20 from "@/components/HomeView/BlockSection20.vue";
import BlockSection22 from "@/components/ContactForm.vue";
import BlockSection21 from "@/components/HomeView/BlockSection21.vue";
import HeroAnimation from "@/components/HomeView/HeroAnimation.vue";
import BlockSection18 from "@/components/HomeView/BlockSection18.vue";
import BlockSection17 from "@/components/HomeView/BlockSection17.vue";
import BlockSection19 from "@/components/HomeView/BlockSection19.vue";
import BlockSection2 from "@/components/HomeView/BlockSection2.vue";
import BlockSection4 from "@/components/HomeView/BlockSection4.vue";
import BlockSection5 from "@/components/HomeView/BlockSection5.vue";
import BlockSection3 from "@/components/HomeView/BlockSection3.vue";
import BlockSection1 from "@/components/HomeView/BlockSection1.vue";
import BlockSection10 from "@/components/HomeView/BlockSection10.vue";
import BlockSection11 from "@/components/HomeView/BlockSection11.vue";
import BlockSection9 from "@/components/HomeView/BlockSection9.vue";
import BlockSection6 from "@/components/HomeView/BlockSection6.vue";
import BlockSection12 from "@/components/HomeView/BlockSection12.vue";
import BlockSection8 from "@/components/HomeView/BlockSection8.vue";
import BlockSection7 from "@/components/HomeView/BlockSection7.vue";
import BlockSection15 from "@/components/HomeView/BlockSection15.vue";
import BlockSection16 from "@/components/HomeView/BlockSection16.vue";
import BlockSection14 from "@/components/HomeView/BlockSection14.vue";
import BlockSection13 from "@/components/HomeView/BlockSection13.vue";
import BlockSection14_1 from "@/components/HomeView/BlockSection14-1.vue";
import { mapGetters, mapMutations } from "vuex";
import eventBus from "@/utils/eventsBus";

export default {
  name: "ContentBlock",
  props: {},
  components: {
    BlockSection13,
    BlockSection14,
    BlockSection14_1,
    BlockSection16,
    BlockSection15,
    BlockSection7,
    BlockSection8,
    BlockSection12,
    BlockSection6,
    BlockSection9,
    BlockSection11,
    BlockSection10,
    BlockSection1,
    BlockSection3,
    BlockSection5,
    BlockSection4,
    BlockSection2,
    BlockSection19,
    BlockSection17,
    BlockSection18,
    HeroAnimation,
    BlockSection21,
    BlockSection22,
    BlockSection20,
  },
  methods: {
    ...mapMutations(["setBurgerItems"]),
    openModalAuthor(id) {
      const author = this.getAuthorById(id);
      eventBus.emit("openAuthorModal", author);
    },
    openProblemModal(id) {
      const problem = this.getCardById(id);
      eventBus.emit("openProblemModal", problem);
    },
  },
  computed: {
    ...mapGetters(["getAuthorById", "getCardById"]),
  },
  mounted() {
    this.setBurgerItems([
      {
        title: "Начало",
        path: "#hero-animation",
        offset: -600,
      },
      {
        title: "Для чего нужен GTS",
        path: "#block-10",
        offset: -150,
      },
      {
        title: "О проблеме",
        path: "#block-15",
        offset: 450,
      },
      {
        title: "О команде",
        path: "#block-21",
        offset: -150,
      },
    ]);
  },
  beforeUnmount() {
    this.setBurgerItems({});
  },
};
</script>

<style scoped></style>
