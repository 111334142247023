<template>
  <section class="wrapper" ref="blockSection" id="block-1">
    <div class="content-block">
      <span class="sub-title top">
        Агрегаторы диктуют <br />
        Вам условия?
      </span>
      <h2 class="hero-title">
        Вас уже отключили от <br />
        международных <br />
        продуктов, лишив ваш <br />
        бизнес конкуренто-<br />способности и замедлив<br />
        его развитие.
      </h2>
      <div class="sub-title bottom">
        <span>
          Вы интересовались<br />
          этими вопросами?
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection1",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 90%",
        end: "top 40%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".sub-title"),
      { scale: 0.5, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".hero-title"),
      { scale: 0.5, autoAlpha: 0 },
      {
        scale: 1,
        autoAlpha: 1,
        duration: 1.5,
        ease: "power2.out",
        delay: 0.5,
      },
      "-=0.5"
    );
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-top: 200px;
}

.content-block {
  padding-top: 50px;
  position: relative;
  max-width: 80vw;
  margin: 0 auto;
}

.hero-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  text-align: center;
  letter-spacing: -0.1706px;
  text-transform: uppercase;
}

.sub-title {
  position: absolute;
  display: inline-block;
  border-radius: 33px;
  padding: 17px 34px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #323233;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  font-size: 23px;
  font-weight: 550;
  line-height: 28px;
  letter-spacing: -0.1706px;
}

.top {
  top: -1em;
  left: -2em;
}

.bottom {
  bottom: -2.7em;
  right: -2em;
}

@media (max-width: 1200px) {
  .top {
    top: -2em;
    left: -2em;
  }

  .bottom {
    bottom: -3.7em;
    right: -2em;
  }

  .hero-title {
    font-size: 45px;
    line-height: 56px;
  }
}
</style>
