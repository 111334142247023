<template>
  <section class="wrapper" id="hero">
    <div class="content">
      <img
        src="@/assets/images/about-problem-hero.png"
        alt="hero-img"
        class="image"
      />
      <h2 class="title">О ПРОБЛЕМЕ</h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutProblemHero",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-top: 160px;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content {
  position: relative;
  height: 90vh;
  width: 100%;
}

.image {
  max-width: 760px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 550;
  line-height: 76px;
  letter-spacing: -0.287px;
  text-align: center;
  text-wrap: nowrap;
}
</style>
