<template>
  <mainWrapper>
    <div ref="mainContainer" class="main-container">
      <router-view />
    </div>
    <ModalAboutAuthor ref="authorModal" />
    <ModalAboutProblem ref="problemModal" />
    <LoadingScreen v-show="loading" />
  </mainWrapper>
</template>

<script>
import MainWrapper from "@/components/MainWrapper.vue";
import ModalAboutAuthor from "@/components/HomeView/ModalAboutAuthor.vue";
import eventBus from "@/utils/eventsBus";
import ModalAboutProblem from "@/components/HomeView/ModalAboutProblem.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
  name: "App",
  components: {
    ModalAboutProblem,
    ModalAboutAuthor,
    MainWrapper,
    LoadingScreen,
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    loading(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  },
  mounted() {
    eventBus.on("openAuthorModal", (data) => {
      this.$refs.authorModal.showModal(data);
    });
    eventBus.on("openProblemModal", (data) => {
      this.$refs.problemModal.showModal(data);
    });
    eventBus.on("loadingTrigger", (data) => {
      this.loading = data;
    });
  },
  beforeUnmount() {
    eventBus.off("openAuthorModal");
    eventBus.off("openProblemModal");
    eventBus.off("loadingTrigger");
  },
};
</script>

<style>
.main-container {
  overflow-y: hidden;
}
</style>
