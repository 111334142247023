<template>
  <section class="wrapper" id="block-5">
    <div class="content">
      <h2 class="title mb-15">
        Тенденция развития AI-сервисов цифровых агрегаторов
      </h2>
      <h3 class="sub-title mt-5">
        это образование
        <span class="outlined-white">цифровых сверх-монополий,</span> <br />
        в которых малый и средний бизнес будет основным<br />
        источником генерации дохода для корпораций.
      </h3>
      <div class="images">
        <img
          v-for="image in images"
          :key="image"
          :src="require(`@/assets/images/${image}`)"
          alt="sources"
          class="mb-8"
        />
      </div>
      <h3 class="sub-title">
        Крупные цифровые агрегаторы
        <span class="outlined-white">уже сейчас</span><br />
        контролируют доходность малого и среднего<br />
        бизнеса, используя свои алгоритмы для<br />
        манипуляции ценами и установления<br />
        маржинальных коридоров для партнеров.
      </h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock5",
  data() {
    return {
      images: [
        "source-apple.png",
        "source-microsoft.png",
        "source-nvidia.png",
        "source-google.png",
        "source-meta.png",
      ],
    };
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.content {
  max-width: 1000px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.sub-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.images {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0;
}
</style>
