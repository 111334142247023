<template>
  <section class="wrapper" id="block-5">
    <div class="content" ref="timeline">
      <div class="card-1958">
        <div class="card-description">
          <span class="outlined-green label">1958 год</span>
          <h3 class="title">первый в мире интернет</h3>
          <p class="description">
            В 1958 году Анатолий Иванович Китов предлагает Н.С. Хрущеву идею
            создания первого в мире интернета. Это была Единая государственная
            сеть вычислительных центров (ЕГСВЦ).
          </p>
        </div>
        <div class="card-image">
          <img
            src="@/assets/images/anatoliy-kitov.png"
            alt="web"
            class="mb-2"
          />
          <h5 class="name">Анатолий Иванович Китов</h5>
          <a
            class="link"
            href="https://www.computer-museum.ru/galglory/kitov0.htm"
            target="_blank"
            rel="noopener noreferrer"
            >Биография: <span class="underline">computer-museum.ru</span></a
          >
        </div>
      </div>
      <div class="timeline">
        <img src="@/assets/images/image-about-gts-block-5.png" alt="web" />
        <img
          src="@/assets/images/image-about-gts-block-5-layer-1.png"
          alt="web"
          class="green-line"
        />
        <div class="card year-1964">
          <div class="img-side">
            <img
              src="@/assets/images/victor-glushkov.png"
              alt="viktor glushkov"
            />
            <div
              class="info d-flex align-items-center justify-content-center flex-column"
            >
              <h4 class="name">Виктор Михайлович Глушков</h4>
              <a
                href="https://glushkov.su/"
                class="link"
                target="_blank"
                rel="noopener noreferrer"
                >Биография: <span class="underline">glushkov.su</span></a
              >
            </div>
          </div>
          <div class="content-side">
            <span class="outlined-green label mb-2">1964 год</span>
            <p class="description">
              В 1964 году Виктор Глушков заложил идею ЕГСВЦ в основу разработки
              ОГАС, предложив перейти от административно-командного управления
              СССР к научному, основанному на экономико-математических методах.
              Проекты свернули по политическим причинам.
            </p>
          </div>
        </div>
        <div
          class="card year-1981 d-flex align-items-center justify-content-between"
        >
          <div class="img-side">
            <img
              src="@/assets/images/kitov-vladimir-director.png"
              alt="viktor glushkov"
            />
            <div
              class="info d-flex align-items-center justify-content-center flex-column"
            >
              <h4 class="name">
                Владимир Анатольевич Китов <br />
                Ведущий архитектор текущей команды GTS
              </h4>
            </div>
          </div>
          <div class="content-side">
            <span class="outlined-green label mb-2">1981 год</span>
            <p class="description">
              В 1981 году под руководством Владимира Китова был создан
              телемонитор “ОБЬ” для обмена данными между Главными
              Вычислительными Центрами Миноборонпрома, Госплана и Минавиапрома
              СССР, и к концу 80-х к нему подключились более 40% промышленных
              предприятий. С началом перестройки его вытеснили крупные западные
              компании, занявшие ключевые IT-рынки под видом “демократичных”
              решений.
            </p>
          </div>
        </div>
        <div class="card year-1996">
          <div class="content-side w-100">
            <span class="outlined-green label mb-2">1996 год</span>
            <p class="description mb-1">
              В 1996 году российская компания ЭПСИЛОН ТЕХНОЛОДЖИС предложила
              альтернативную концепцию интернета, разработав с учеными и ЦНИИ
              «МОНОЛИТ» телекоммуникационный монитор «BAIKONUR» на основе
              протоколов TCP/IP.
            </p>
            <p class="description">
              Эта система позволяла быстро создавать программные архитектуры для
              различных рынков и обеспечивала работу таких крупных организаций,
              как Центробанк России, ФАПСИ, Госдума, Аэрофлот и др.
            </p>
          </div>
        </div>
        <div class="card year-1998">
          <div class="content-side w-50">
            <span class="outlined-green label mb-2">1998 год</span>
            <p class="description mb-1">
              18 июня 1998 года на совместной конференции Epsylon Technologies и
              Intel, с участием более 130 корпоративных заказчиков, включая
              представителей аппарата Президента РФ, Госдумы, ФАПСИ, ЦБ и
              «Газпрома», были представлены результаты испытаний ПО «Baikonur» в
              центре Intel в Москве.
            </p>
          </div>
          <div class="content-side w-50">
            <img src="@/assets/icons/intel.png" alt="intel" />
            <p class="description mb-1">
              В то время более 50% интернет-трафика в США для сеттобоксов и WEB
              TV генерировалось серверами на ПО «Baikonur». По итогам тестов,
              Intel отметила: «Сервер показал отличную масштабируемость без
              необходимости оптимизации, даже при эмуляции работы 10 тыс.
              пользователей и 760 приложений одновременно».
            </p>
          </div>
        </div>
        <div class="card year-2002">
          <div class="content-side w-100">
            <span class="outlined-green label mb-2">2002 год</span>
            <h4 class="title">энергоносители в обмен на западные технологии</h4>
            <p class="description mb-1">
              Активное развитие опередившей свое время технологии «Baikonur»
              было свернуто после достижения негласного консенсуса
              «энергоносители в обмен на западные технологии».
            </p>
          </div>
        </div>
        <div class="card year-2014">
          <div class="content-side w-75 pr-0">
            <span class="outlined-green label mb-2">2014 год</span>
            <h4 class="title">интернет объектов</h4>
            <p class="description mb-1">
              В 2014 году была сформулирована идея нового глобального
              интернет-сервиса – интернета объектов, ставшая следующим этапом
              развития «Baikonurа».
            </p>
          </div>
          <div class="img-side w-25 pl-1">
            <img
              src="@/assets/images/internet-image.png"
              alt="viktor glushkov"
            />
          </div>
        </div>
        <div class="card year-2015">
          <div class="content-side w-100">
            <span class="outlined-green label mb-2">2015 год</span>
            <p class="description">
              В 2015 году при разработке многомерной системы управления ПАО
              «РусГидро» были сформулированы требования для российского базового
              стека технологий, ставшего основой технологии GTS (GREENER
              TRANSACTION SERVER). Анализ показал, что системы «Обь» и
              «Baikonur» превосходят транзакционные системы IBM CICS TS.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "AboutGtsBlock5",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.timeline;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 70%",
        end: "top -70%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".green-line"),
      { clipPath: "inset(0 0 100% 0)" },
      {
        clipPath: "inset(0 0 0% 0)",
        duration: 1,
        ease: "linear",
      }
    );

    const elements = [
      { className: ".card-1958", start: "top 80%", end: "top 10%" },
      { className: ".year-1964", start: "top 80%", end: "top 10%" },
      { className: ".year-1981", start: "top 80%", end: "top 10%" },
      { className: ".year-1996", start: "top 80%", end: "top 10%" },
      { className: ".year-1998", start: "top 80%", end: "top 10%" },
      { className: ".year-2002", start: "top 80%", end: "top 10%" },
      { className: ".year-2014", start: "top 80%", end: "top 10%" },
      { className: ".year-2015", start: "top 80%", end: "top 10%" },
    ];

    elements.forEach((el) => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: el.className,
            scroller: document.querySelector(".main-container"),
            start: el.start,
            end: el.end,
            scrub: true,
            onEnter: () => {
              window.locoScroll.update();
            },
            onLeave: () => {
              window.locoScroll.update();
            },
          },
        })
        .fromTo(el.className, { opacity: 0 }, { opacity: 1, duration: 1 })
        .to(el.className, { opacity: 0, duration: 1 });
    });
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.content {
  max-width: 1340px;
  padding: 0 20px;
}

.card-1958 {
  border-left: 2px solid;
  border-image-source: linear-gradient(180deg, #2da01a 0%, #000000 100%);
  border-image-slice: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-bottom: 40px;
  max-width: 600px;
  margin-left: 1%;
}

.card-description {
  width: 60%;
}

.label {
  font-size: 16px;
  font-weight: 550;
  line-height: 17px;
  letter-spacing: -0.0713px;
  text-align: left;
  padding: 8px 16px;
  display: inline-block;
}

.title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.28799px;
  text-align: left;
  margin: 12px 0;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: none;
}

.card-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.2879px;
  text-align: center;
  opacity: 0.5;
  text-transform: none;
}

.link {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.2879px;
  text-align: center;
  opacity: 0.5;
  text-transform: none;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  opacity: 0.9;
}

.timeline {
  position: relative;
}

.green-line {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.card {
  position: absolute;
  z-index: 2;
  border-right: 2px solid;
  border-image-source: linear-gradient(180deg, #2da01a 0%, #000000 100%);
  border-image-slice: 1;
  max-width: 530px;
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.year-1964 {
  top: -7.3%;
  right: 17.6%;
  opacity: 0;
  padding-bottom: 50px;
}

.img-side {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.content-side {
  width: 60%;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.year-1981 {
  max-width: 650px;
  top: 10.2%;
  right: 17.6%;
  opacity: 0;
}

.year-1981 .img-side img {
  max-width: 75px;
}

.year-1996 {
  max-width: 340px;
  top: 16.5%;
  left: 1.2%;
  opacity: 0;
}

.year-1998 {
  max-width: 620px;
  padding-bottom: 10px;
  top: 36.1%;
  left: 27.65%;
  border-left: 2px solid;
  border-right: none;
  padding-left: 20px;
  padding-right: 0;
  opacity: 0;
}

.year-2002 {
  max-width: 350px;
  border-left: 2px solid;
  border-right: none;
  padding-left: 20px;
  padding-right: 0;
  padding-bottom: 20px;
  top: 42.6%;
  right: -1.7%;
  opacity: 0;
}

.year-2014 {
  max-width: 450px;
  bottom: 24%;
  right: 25.1%;
  opacity: 0;
}

.year-2015 {
  max-width: 320px;
  top: 71%;
  left: 3.5%;
  opacity: 0;
}
</style>
