<template>
  <section class="wrapper" ref="blockSection" id="block-11">
    <h3 class="sub-title mb-10 outlined-green">преимущество №1</h3>
    <div class="title-wrapper">
      <div class="wrap">
        <div class="title">
          масштабируемые<br />
          приложения для<br />
          малого бизнеса
        </div>
      </div>
    </div>
    <p class="description mt-15">
      GTS позволяет малому бизнесу создавать<br />
      масштабируемые приложения для любых устройств,<br />
      обслуживающие миллионы пользователей, с<br />
      минимальными затратами и без переписывания системы.
    </p>
    <div class="divider-vertical" style="height: 140px; margin: 120px 0"></div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection11",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 70%",
        end: "top 10%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".sub-title"),
      { autoAlpha: 0, translateY: -100 },
      { autoAlpha: 1, translateY: 0, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".title-wrapper"),
      { scale: 2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".wrap"),
      { scale: 2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=2"
    );

    timeline.fromTo(
      blockSection.querySelector(".title"),
      { scaleY: 0.2, autoAlpha: 0 },
      { scaleY: 1, autoAlpha: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".description"),
      { y: 100, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".divider-vertical"),
      { height: 1 },
      { height: 140, duration: 1 },
      "=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.sub-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
  position: relative;
  padding: 30px;
}

.title-wrapper {
  position: relative;
}

.wrap {
  position: relative;
}

.wrap::before,
.wrap::after {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 20px 0 20px 0;
}

.wrap::before {
  top: 0;
  left: 0;
  border-top: 5px solid #2da01a;
  border-left: 5px solid #2da01a;
}

.wrap::after {
  bottom: 0;
  right: 0;
  border-bottom: 5px solid #2da01a;
  border-right: 5px solid #2da01a;
}

.title-wrapper::before,
.title-wrapper::after {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 0 20px 0 20px;
}

.title-wrapper::before {
  top: 0;
  right: 0;
  border-top: 5px solid #2da01a;
  border-right: 5px solid #2da01a;
}

.title-wrapper::after {
  bottom: 0;
  left: 0;
  border-bottom: 5px solid #2da01a;
  border-left: 5px solid #2da01a;
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
