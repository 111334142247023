<template>
  <section class="wrapper" id="block-12">
    <img
      src="@/assets/images/blur-background-problem-block-12.png"
      alt="blur-background"
      class="blur-background"
    />
    <div class="content-block">
      <h2 class="title">
        Методика расчета TCO была разработана Gartner Group для предоставления
        комплексной оценки всех затрат, связанных с жизненным циклом цифрового
        решения.
      </h2>
      <h2 class="title mt-10">
        Она учитывает все затраты, которые могут<br />
        возникнуть в процессе эксплуатации, включая:
      </h2>
      <div class="card-container my-20">
        <div class="card mb-5" v-for="card in cards" :key="card.img">
          <img
            :src="require(`@/assets/images/${card.img}`)"
            alt="card-background"
          />
        </div>
      </div>
      <h2 class="title">
        Затраты на владение цифровой инфраструктурой для<br />
        малого и среднего бизнеса будут
        <span class="outlined-white">неуклонно расти</span> <br />
        из-за увеличения расходов на поддержку, усложнения инфраструктуры,
        инфляции и роста цен на IT-услуги, а также потребности в обучении
        персонала.
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock12",
  data() {
    return {
      cards: [
        {
          img: "card-1-problem-block-12.png",
        },
        {
          img: "card-2-problem-block-12.png",
        },
        {
          img: "card-3-problem-block-12.png",
        },
      ],
    };
  },
};
</script>

<style scoped>
.wrapper {
  margin: 150px 0 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.content-block {
  max-width: 1020px;
  width: 100%;
  padding: 0 20px;
  margin-top: 150px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
}

.title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}
</style>
