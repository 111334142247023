<template>
  <section class="wrapper" ref="blockSection" id="block-3">
    <img class="hero-img" src="@/assets/images/image-block-3.png" alt="img" />
    <h2 class="hero-title mb-20">
      <span class="outlined-white">1% компаний</span> <br />
      контролируют цифровую <br />
      инфраструктуру, <br />стандарты и извлекают <br />
      прибыль из ваших данных.
    </h2>
    <h2 class="hero-title mt-20 second">
      В игре с цифровым <br />
      агрегатором,<br />
      как в казино, <br />
      <span class="outlined-white">гарантированно</span> <br />
      выигрывает только <br />
      владелец цифровой <br />монополии.
    </h2>
    <p class="sub-title mt-20">экономика неравенства</p>
    <span class="description mt-20"> 1% получает все </span>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection3",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 90%",
        end: "top -200%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });
    timeline.fromTo(
      blockSection.querySelector(".hero-img"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".hero-title"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".second"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".description"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    const timeline2 = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection.querySelector(".description"),
        scroller: document.querySelector(".main-container"),
        start: "top 60%",
        end: "top 0%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline2.fromTo(
      blockSection.querySelector(".description"),
      { scale: 1, autoAlpha: 1, y: 0 },
      { scale: 4, autoAlpha: 0, y: 100, duration: 1 },
      "=1.5"
    );
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.hero-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}

.outlined-white {
  background-color: white;
  color: #000;
  border-radius: 23px;
  padding: 0 20px;
}

.sub-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.3457px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 75px;
}

.description {
  font-family: "Adieu Bold", sans-serif;
  font-size: 76px;
  font-weight: 700;
  line-height: 91px;
  letter-spacing: -0.3457px;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 1000px) {
  .hero-title {
    font-size: 45px;
    line-height: 46px;
  }
}
</style>
