<template>
  <section class="wrapper" ref="blockSection" id="block-17">
    <div class="content">
      <div class="frame"></div>
      <h3 class="sub-title outlined mb-15 animation-1">главная проблема</h3>
      <h2 class="title animation-2">
        Отсутствие направлений<br />
        для развития и<br />
        инвестирования в условиях<br />
        растущей конкуренции.
      </h2>
      <div class="divider-vertical-dashed mb-15" style="height: 162px"></div>
      <div class="sub-title outlined-red mb-15 animation-3">боль</div>
      <h2 class="title mb-15">
        недостаточная цифровая<br />
        инфраструктура и слабая<br />
        защита данных
      </h2>
      <h2 class="title mb-15">
        Интернет всего (I<span class="no-uppercase">o</span>E) -<br />
        идеальный рынок для<br />
        освоения и инвестирования,<br />
        доступный любому бизнесу
      </h2>
      <div class="tail">
        <h4 class="tail-sub-tittle mb-5">Однако,</h4>
        <h3 class="tail-tittle mb-7">
          недостаточная цифровая<br />
          инфраструктура и слабая<br />
          защита данных<br />
          ограничивают этот рост,
        </h3>
        <h4 class="tail-sub-tittle">
          требуя срочных решений для снижения рисков<br />
          и раскрытия потенциала IoE.
        </h4>
      </div>
      <div class="graphic mt-15">
        <img
          src="@/assets/images/image-graphic-block-17.png"
          alt="Graphic"
          class="img-graphic"
        />
        <h4 class="graphic-title name">
          Рост рынка I<span class="no-uppercase">o</span>E до 2030 года
        </h4>
        <a
          href="https://www.statista.com/statistics/976313/global-iot-market-size/"
          target="_blank"
          rel="noopener noreferrer"
          class="graphic-link name"
          >Источник: <span class="underline">statista.com</span></a
        >
      </div>
      <div class="divider-vertical-dashed mb-15" style="height: 162px"></div>
      <div class="sub-title outlined-green anim-block-1">решение</div>
      <h2 class="title my-10 anim-block-2">
        Новые возможности для<br />
        I<span class="no-uppercase">o</span>E и стратегические<br />
        инвестиции
      </h2>
      <h4 class="sub-title anim-block-3">
        Технология GTS открывает новые горизонты для I<span class="no-uppercase"
          >o</span
        >E,<br />
        обеспечивая стабильность и безопасность<br />
        подключенных устройств, решая проблемы надежности<br />
        софта и предлагая масштабируемые решения.
      </h4>
      <div class="tail-green">
        <h4 class="sub-title-tile">Участие в консорциуме ОНТОСЕТЬ это</h4>
        <h3 class="title-tile">
          стратегический шаг и<br />
          уникальная возможность<br />
          для первопроходцев.
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection17",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 40%",
        end: "top -250%",
        scrub: 0.8,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".frame"),
      { clipPath: "inset(0 0 100% 0)" },
      {
        clipPath: "inset(0 0 0% 0)",
        duration: 1,
        ease: "linear",
      }
    );

    const elements = [
      {
        className: ".animation-1",
        start: "top 10%",
        end: "top -20%",
        from: { autoAlpha: 0, y: -120 },
        to: { autoAlpha: 1, y: 0, duration: 1 },
      },
      {
        className: ".animation-2",
        start: "top 10%",
        end: "top -20%",
        from: { autoAlpha: 0, scale: 1.2 },
        to: { autoAlpha: 1, scale: 1, duration: 1 },
      },
      {
        className: ".animation-3",
        start: "top 10%",
        end: "top -20%",
        from: { autoAlpha: 0, scaleX: 2 },
        to: { autoAlpha: 1, scaleX: 1, duration: 1 },
      },
      {
        className: ".tail",
        start: "top 10%",
        end: "top -20%",
        from: { autoAlpha: 0, scale: 0.5 },
        to: { autoAlpha: 1, scale: 1, duration: 1 },
      },
      {
        className: ".img-graphic",
        start: "top -10%",
        end: "top -40%",
        from: { clipPath: "inset(0 100% 0 0)" },
        to: {
          clipPath: "inset(0 0% 0 0)",
          duration: 1,
          ease: "linear",
        },
      },
      {
        className: ".name",
        start: "top 10%",
        end: "top -20%",
        from: { autoAlpha: 0 },
        to: { autoAlpha: 1, duration: 1 },
      },
      {
        className: ".anim-block-1",
        start: "top 20%",
        end: "top -20%",
        from: { scaleX: 2, autoAlpha: 0 },
        to: { scaleX: 1, autoAlpha: 1, duration: 0.7 },
      },
      {
        className: ".anim-block-2",
        start: "top 10%",
        end: "top -20%",
        from: { autoAlpha: 0 },
        to: { autoAlpha: 1, duration: 0.7 },
      },
      {
        className: ".anim-block-3",
        start: "top 10%",
        end: "top -20%",
        from: { y: 100, autoAlpha: 0 },
        to: { y: 0, autoAlpha: 1, duration: 0.7 },
      },
      {
        className: ".tail-green",
        start: "top 30%",
        end: "top -10%",
        from: { autoAlpha: 0, scale: 0.5 },
        to: { autoAlpha: 1, scale: 1, duration: 1 },
      },
    ];

    elements.forEach((el) => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: blockSection.querySelector(el.className),
            scroller: document.querySelector(".main-container"),
            start: el.start,
            end: el.end,
            scrub: 0.4,
            onEnter: () => {
              window.locoScroll.update();
            },
            onLeave: () => {
              window.locoScroll.update();
            },
          },
        })
        .fromTo(blockSection.querySelector(el.className), el.from, el.to);
    });
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.content {
  position: relative;
  max-width: 70%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))
      padding-box,
    linear-gradient(180deg, #ffffff33, #00000033) border-box;

  border: 2px solid transparent;
  border-radius: 146px 146px 0 0;
}

.sub-title {
  display: inline-block;
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: center;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.tail {
  padding: 35px;
  border-radius: 30px;
  background: linear-gradient(180deg, #320c05, rgba(0, 0, 0, 1)) padding-box,
    linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 1))
      border-box;

  border: 2px solid transparent;
}

.tail-tittle {
  font-family: "Adieu Light", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.graphic {
  position: relative;
}

.graphic-title {
  position: absolute;
  top: 30%;
  left: 30px;
  font-size: 19px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3329px;
  text-align: left;
}

.graphic-link {
  position: absolute;
  bottom: 0;
  right: 40px;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.3329px;
  text-align: right;
  opacity: 0.5;
  text-transform: none;
}

.tail-green {
  margin-top: 100px;
  padding: 35px;
  border-radius: 30px;
  background: linear-gradient(180deg, #0e240b, rgba(0, 0, 0, 1)) padding-box,
    linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 1))
      border-box;

  border: 2px solid transparent;
}

.sub-title-tile {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.title-tile {
  font-family: "Adieu Light", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}
</style>
