<template>
  <section class="wrapper" id="block-26">
    <div class="content">
      <h2 class="sub-title">
        Крупные IT-гиганты вынуждены монополизировать рынок чтобы справиться с
        вызовами цифровой среды.
      </h2>
      <p class="text">
        С развитием AI крупные компании заходят на территории малых и средних
        предприятий, диктуя доходы и обязательные расходы.
      </p>
      <h2 class="title">закономерность</h2>
      <div class="card">
        <p class="text">
          рост затрат на поддержание критической информационной инфраструктуры
          при сокращении отдачи от цифровых решений - объективный процесс,
          вынуждающий IT-игроков монополизировать рынки и брать под контроль
          малый и средний бизнес.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock26",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  padding-top: 100px;
  text-transform: uppercase;
}

.content {
  max-width: 1120px;
  width: 100%;
  padding: 20px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("@/assets/images/blur-problem-block-26.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom -80px;
}

.sub-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 150px;
}

.text {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: -0.3463px;
  text-align: center;
  padding-top: 100px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  border: 2px solid #333;
  border-radius: 40px;
  margin-top: 70px;
}
</style>
