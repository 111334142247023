<template>
  <div class="hero-start" v-show="!loadingVideo">
    <HeroVideo
      videoSrc="./video/Hero-Block-Video.mp4"
      :startPosition="800"
      :speed="2.5"
      @loadingVideoFinish="() => (this.loadingVideo = false)"
      @videoEnd="videoEnd"
      @wheelUp="() => (this.startText = true)"
      @wheelDown="() => (this.startText = false)"
    />
    <transition
      name="slide-fade"
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <HeroScreensaver
        v-if="!loadingVideo && startText"
        class="hero-text"
        ref="heroText"
      />
    </transition>
  </div>
</template>

<script>
import HeroVideo from "@/components/HomeView/HeroVideo.vue";
import HeroScreensaver from "@/components/HomeView/HeroScreensaver.vue";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default {
  name: "HeroBlock",
  components: { HeroScreensaver, HeroVideo },
  props: {
    showContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingVideo: false,
      startText: true,
    };
  },
  methods: {
    videoEnd() {
      this.$emit("videoEnd");
    },
    beforeEnter(el) {
      el.style.transform = "translate(-50%, 50%)";
      el.style.opacity = "0";
    },
    enter(el, done) {
      setTimeout(() => {
        el.style.transition = "all 0.5s ease";
        el.style.transform = "translate(-50%, -50%)";
        el.style.opacity = "1";
        done();
      });
    },
    leave(el, done) {
      el.style.transition = "all 0.5s ease";
      el.style.transform = "translate(-50%, -100%)";
      el.style.opacity = "0";
      setTimeout(() => done(), 500);
    },
  },
  mounted() {
    const targetElement = document.querySelector("#app");
    if (targetElement) {
      disableBodyScroll(targetElement);
    }
    setTimeout(() => window.locoScroll.stop(), 600);
  },
  beforeUnmount() {
    window.locoScroll.destroy();
    const targetElement = document.querySelector("#app");
    if (targetElement) {
      enableBodyScroll(targetElement);
    }
  },
};
</script>

<style scoped>
.hero-start {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
</style>
