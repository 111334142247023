<template>
  <section class="wrapper" id="block-15">
    <div class="tile-row">
      <div class="white-card">
        <h3 class="tile-content">
          <img src="@/assets/icons/icon-black-snow.png" alt="snow-black" />
          {{ selectedCard.title }}
        </h3>
        <p class="text">
          {{ selectedCard.description }}
        </p>
      </div>
      <div
        :class="['black-card', activeButton === 1 ? 'green-bg' : 'white-bg']"
      >
        <div class="buttons-row">
          <div
            class="button"
            :class="{ active: activeButton === 0 }"
            @click="selectCard(0)"
          >
            Без GTS
          </div>
          <div
            class="button"
            :class="{ active: activeButton === 1 }"
            @click="selectCard(1)"
          >
            с GTS
          </div>
        </div>
        <h3 class="card-title">
          {{ selectedCard.time }}<span style="font-style: italic">Ч</span>
        </h3>
        <p class="card-description my-5">
          Рабочего времени сетевого инженера в неделю на обеспечение
          безопасности и безотказности серверов банка
        </p>
      </div>
    </div>
    <div class="divider-horizontal mb-0" style="max-width: 60%"></div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock15",
  data() {
    return {
      activeButton: 0,
      cardsInfo: [
        {
          title: "Типичный российский банк без GTS",
          description:
            "Имеет ряд типичных проблем сферы: слабая система безопасности, зависимость от западной инфраструктуры, низкая стабильность серверов моментальных платежей.",
          time: "50",
        },
        {
          title: "Типичный российский банк c GTS",
          description:
            "Лишен большинства проблем сферы и обеспечивает стабильность работы всех систем, является независимым и безопасным.",
          time: "5",
        },
      ],
    };
  },
  computed: {
    selectedCard() {
      return this.cardsInfo[this.activeButton];
    },
  },
  methods: {
    selectCard(index) {
      this.activeButton = index;
    },
  },
};
</script>

<style scoped>
.wrapper {
  margin: 150px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.tile-row {
  display: flex;
  align-items: stretch;
  border: 2px solid #333;
  border-radius: 40px;
  max-width: 1420px;
  width: 100%;
  min-height: 50px;
  height: 100%;
}

.white-card {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 20px;
  padding: 34px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  border: 1px solid #333;
  color: black;
}

.black-card {
  position: relative;
  flex-grow: 1;
  padding: 34px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.black-card::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: -1;
}

.white-bg {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.25),
    transparent 60%
  );
}

.green-bg::before {
  background: radial-gradient(circle, #2da01a96, transparent 40%);
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.tile-content {
  font-size: 48px;
  font-weight: 550;
  line-height: 57px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.card-title {
  font-size: 200px;
  font-weight: 550;
  line-height: 240px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card-description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
  max-width: 450px;
}

.buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff33;
  border-radius: 40px;
  padding: 5px;
  gap: 10px;
  width: 240px;
}

.button {
  border-radius: 40px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2879px;
  text-align: left;
  padding: 12px 20px;
  text-wrap: nowrap;
  cursor: pointer;
}

.active {
  background-color: #eb4a27;
}
</style>
