<template>
  <section class="wrapper" ref="blockSection" id="block-12">
    <img class="image" src="@/assets/images/image-block-12.png" alt="img" />
    <h3 class="sub-title mt-15 outlined-green">преимущество №2</h3>
    <h2 class="title my-15">
      быстрое создание и<br />
      адаптация продуктов
    </h2>
    <p class="content">
      Среда разработки GTS позволяет собирать<br />
      решения любой сложности
      <span class="outlined-white green">как из кубиков Lego,</span><br />
      ускоряя создание продуктов и адаптацию <br />
      архитектур под новые рынки.
    </p>
    <div
      class="divider-vertical"
      style="height: 140px; margin-top: 120px"
    ></div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection12",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 30%",
        end: "top -50%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".image"),
      { autoAlpha: 0, scaleY: 0 },
      { autoAlpha: 1, scaleY: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".sub-title"),
      { scale: 1.5, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".title"),
      { scaleY: 0.2, autoAlpha: 0 },
      { scaleY: 1, autoAlpha: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".content"),
      { y: 100, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".divider-vertical"),
      { height: 1 },
      { height: 140, duration: 1 },
      "=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sub-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.content {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
