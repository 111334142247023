<template>
  <section class="wrapper" ref="blockSection" id="block-15">
    <div class="tail d-flex align-items-center">
      <div class="side left background flex-grow-1">
        <span class="text">ПРОБЛЕМ</span>
        <div
          class="divider-horizontal mx-5 text"
          style="max-width: 100px"
        ></div>
      </div>
      <div class="middle d-flex align-items-center">
        <span class="text">7</span>
      </div>
      <div class="side right background flex-grow-1">
        <div
          class="divider-horizontal mx-5 text"
          style="max-width: 100px"
        ></div>
        <span class="text">РЕШЕНИЙ</span>
      </div>
    </div>
    <h4 class="sub-title">
      Современные цифровые инструменты и стандарты<br />
      <span class="outlined-white red">не выведут вас из</span>
      ловушки текущих IT-технологий.
    </h4>
    <h3 class="description mt-15">
      НО ЕСТЬ РЕШЕНИЕ:<br />
      БАЗОВЫЙ СТЕК GTS - ОСНОВА КИБЕРНЕТИЧЕСКОЙ<br />
      ЭКОНОМИКИ БУДУЩЕГО
    </h3>
    <div class="divider-vertical mt-0 mb-15" style="height: 108px"></div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection15",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 0px",
        end: "top -800px",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelectorAll(".background"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelectorAll(".text"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 },
      "=1"
    );

    timeline.fromTo(
      blockSection.querySelector(".sub-title"),
      { scaleY: 0.2, autoAlpha: 0 },
      { scaleY: 1, autoAlpha: 1, duration: 1 },
      "=3"
    );

    timeline.fromTo(
      blockSection.querySelector(".description"),
      { y: 100, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 1 },
      "=2.5"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.tail {
  width: 100%;
}

.side {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: left;
  display: flex;
  align-items: center;
}

.left {
  background-image: url("@/assets/images/image-block-15-left.png");
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
  padding: 250px 0;
  justify-content: end;
}

.right {
  background-image: url("@/assets/images/image-block-15-right.png");
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
  padding: 250px 0;
  justify-content: start;
}

.middle {
  font-family: "Adieu Bold", sans-serif;
  font-size: 200px;
  font-weight: 700;
  line-height: 180px;
  letter-spacing: -1.3788px;
  text-align: center;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
}

.description {
  font-size: 32px;
  font-weight: 550;
  line-height: 38px;
  letter-spacing: -0.086px;
  text-align: center;
  padding: 6px 20px;
  border: 1px solid #ffffff33;
  border-radius: 24px;
}
</style>
