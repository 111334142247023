<template>
  <div class="page-wrapper">
    <HeadMenu />
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu.vue";

export default {
  name: "MainWrapper",
  components: { HeadMenu },
};
</script>

<style scoped></style>
