import { reactive } from "vue";

const eventBus = reactive({
  events: {},
  emit(event, payload) {
    if (this.events[event]) {
      this.events[event].forEach((callback) => callback(payload));
    }
  },
  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  },
  off(event) {
    if (this.events[event]) {
      delete this.events[event];
    }
  },
});

export default eventBus;
