<template>
  <section class="wrapper" id="block-6">
    <div class="content">
      <div class="title-block">
        <img
          src="@/assets/images/image-problem-block-6-title-background.png"
          alt="background"
        />
        <h2 class="title">
          искусственный<br />
          интеллект лишь усилит<br />
          эту тенденцию
        </h2>
      </div>
      <div class="card-block" ref="content">
        <div
          class="card-row mb-15"
          v-for="(card, i) in cards"
          :key="card.img"
          :class="i % 2 === 0 ? 'justify-content-start' : 'justify-content-end'"
        >
          <div class="img-container" :class="`card-${i + 1}`">
            <img
              :src="require(`@/assets/images/${card.img}`)"
              alt="image-news"
            />
            <a
              class="card-link"
              target="_blank"
              rel="noopener noreferrer"
              :href="card.link"
              >Узнать больше</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "ProblemBlock6",
  data() {
    return {
      cards: [
        {
          img: "news-google.png",
          link: "https://accesstomarkets.org/",
        },
        {
          img: "news-facebook.png",
          link: "https://www.economicliberties.us/our-work/the-truth-about-google-facebook-and-small-businesses/",
        },
        {
          img: "news-amazon.png",
          link: "https://economictimes.indiatimes.com/news/international/us/what-is-amazons-secret-pricing-strategy-and-what-is-the-ftc-lawsuit-alleging-cost-manipulation-tactics/articleshow/104966949.cms?from=mdr",
        },
        {
          img: "news-uber.png",
          link: "https://themarkup.org/working-for-an-algorithm/2022/03/01/secretive-algorithm-will-now-determine-uber-driver-pay-in-many-cities",
        },
      ],
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const content = this.$refs.content;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: content,
        scroller: document.querySelector(".main-container"),
        start: "top 90%",
        end: "top -30%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });
    this.cards.forEach((el, index) => {
      timeline.fromTo(
        content.querySelector(`.card-${index + 1}`),
        { clipPath: "inset(0 100% 0 0)" },
        {
          clipPath: "inset(0 0% 0 0)",
          duration: 1,
          ease: "linear",
        },
        "-=0"
      );
    });
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
  background-image: url("@/assets/images/image-problem-block-6-background.png");
  background-repeat: no-repeat;
  background-size: 70% 90%;
  background-position: center calc(50% + 400px);
}

.content {
  max-width: 1000px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-block {
  position: relative;
  z-index: 1;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  z-index: 2;
}

.card-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.img-container {
  position: relative;
}

.card-link {
  position: absolute;
  bottom: 10%;
  left: 5%;
  display: block;
  font-size: 14px;
  font-weight: 550;
  line-height: 17px;
  letter-spacing: -0.0995px;
  text-align: left;
  padding: 7px 23px;
  border-radius: 28px;
  border: 1px solid white;
}
</style>
