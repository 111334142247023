<template>
  <section class="wrapper" id="block-10" ref="content">
    <div class="title-block">
      <h2 class="title">
        Наша миссия –<br />
        предоставить<br />
        децентрализованную<br />
        инфраструктуру, уравнивая<br />
        шансы малого и среднего<br />
        бизнеса с корпорациями.
      </h2>
    </div>
    <div class="divider-horizontal"></div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "ProblemBlock10",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const content = this.$refs.content;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: content,
        scroller: document.querySelector(".main-container"),
        start: "top 80%",
        end: "top 10%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      content,
      { backgroundPositionY: "300%" },
      { backgroundPositionY: "0%", duration: 1 },
      "-=0"
    );

    timeline.fromTo(
      content.querySelector(".title"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "=1"
    );
  },
};
</script>

<style scoped>
.wrapper {
  margin: 150px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  background-image: url("@/assets/images/bacground-problem-block-10.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

.title-block {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin-top: 150px;
  margin-bottom: 100px;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
