<template>
  <section class="wrapper" id="block-21">
    <div class="content-block">
      <AuthorTail
        v-for="data in getAuthors"
        :data="data"
        :key="data.id"
        @click="openModal(data.id)"
      />
      <img
        src="@/assets/images/image-block-21-background.png"
        alt="background"
        class="background-image"
      />
      <div class="overlay"></div>
      <div class="overlay-2"></div>
      <img
        class="sun"
        src="@/assets/images/image-block-21-sun.png"
        alt="sun"
        ref="sunElement"
      />
      <div class="content">
        <h2 class="title">О команде GTS</h2>
      </div>
    </div>
  </section>
</template>

<script type="js">
import AuthorTail from "@/components/HomeView/AuthorTail.vue";
import { mapGetters } from "vuex";

export default {
  name: "BlockSection21",
  components: { AuthorTail },
  mounted() {
    this.angle = 0;
    this.rotateSun();
  },
  methods: {
    openModal(id) {
      this.$emit("showAuthorModal", id);
    },
    rotateSun() {
      const sunElement = this.$refs.sunElement;
      if (sunElement) {
        this.angle += 0.025;
        sunElement.style.transform = `rotate(${this.angle}deg)`;
        requestAnimationFrame(this.rotateSun);
      }
    },
  },
  computed: {
    ...mapGetters(["getAuthors"]),
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 100px;
}

.content-block {
  position: relative;
  margin: 0 auto;
}

.background-image {
  width: 100%;
  height: auto;
  max-width: 1700px;
}

.sun {
  position: absolute;
  max-width: 550px;
  width: 32vw;
  height: auto;
  bottom: 1%;
  left: 22%;
  z-index: 4;
  transition: transform 0.1s linear;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  z-index: 2;
}

.overlay-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  z-index: 3;
}

.content {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
