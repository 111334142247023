<template>
  <section class="wrapper" id="block-4">
    <div class="content pt-10">
      <h2 class="title my-15">мир искусственного интеллекта</h2>
      <p class="description mb-10">
        ИИ часто представляют как универсальное<br />
        решение, но его внедрение требует значительных<br />
        ресурсов,
        <span class="outlined-white">доступных лишь крупным компаниям.</span
        ><br />
        <span class="outlined-white"
          >Это усиливает неравенство и зависимость</span
        >
        малых<br />
        и средних бизнесов.
      </p>
      <p class="description">
        Малые компании, использующие чужие AI-модели,<br />
        сталкиваются с рисками утечки данных и<br />
        неравной конкуренции, что делает их
        <span class="outlined-white">уязвимыми</span><br />
        перед крупными игроками.
      </p>
      <div class="card">
        <div class="card-content">
          <h3 class="card-title mb-4">
            Этика и Политика<br />
            в Разработке ИИ
          </h3>
          <p class="card-description">
            Стремясь к получению новых данных,<br />
            компании иногда могут игнорировать<br />
            этические нормы и корпоративные<br />
            политики.
          </p>
          <p class="card-description mt-4">
            Например, OpenAI разработала систему<br />
            распознавания речи Whisper, которая<br />
            расшифровывает аудиозаписи из видео<br />
            на YouTube для обучения искусственного<br />
            интеллекта.
          </p>
          <a
            class="source-link no-uppercase mt-4"
            href="https://rg.ru/2024/04/09/dannye-dlia-obucheniia-ii-zakanchivaiutsia.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Источник:
            <span class="underline">rg.ru</span>
          </a>
        </div>
        <img src="@/assets/images/AI-logo.png" alt="Ai logo" class="image" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock4",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content {
  max-width: 1300px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/images/problem-block-4-background.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center calc(100% - 100px);
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.description {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card {
  display: flex;
  background-color: #000;
  border-radius: 40px;
  border: 1px solid #333;
  min-height: 100px;
  margin-top: 150px;
}

.card-content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.card-title {
  font-size: 40px;
  font-weight: 550;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.card-description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.image {
  object-fit: contain;
}

.source-link {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  opacity: 0.5;
}
</style>
