<template>
  <section class="wrapper" id="block-25" ref="content">
    <div class="content-block">
      <h4 class="section-title outlined-white mb-15">ПРОБЛЕМА №5</h4>
      <h2 class="title">
        Монополизация ниш крупными<br />
        IT-платформами<br />
        и агрегаторами данных.
      </h2>
    </div>
    <div class="slider-wrapper">
      <div
        class="swiper-btn prev"
        @click="prevSlide"
        :class="isBeginning ? 'disabled' : ''"
      >
        <img
          src="@/assets/icons/swiper-btn-icon.png"
          alt="prev"
          class="prev-img"
        />
      </div>
      <div
        class="swiper-btn next"
        @click="nextSlide"
        :class="isEnd ? 'disabled' : ''"
      >
        <img src="@/assets/icons/swiper-btn-icon.png" alt="prev" class="next" />
      </div>
      <Swiper
        ref="slider"
        :slides-per-view="1"
        :space-between="30"
        grabCursor
        class="swiper"
      >
        <SwiperSlide v-for="(slide, i) in sliders" :key="i">
          <img :src="require(`@/assets/images/${slide}`)" alt="slide" />
        </SwiperSlide>
      </Swiper>
    </div>
    <img
      class="slider message"
      src="@/assets/images/image-problem-block-25.png"
      alt="slider"
    />
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
  name: "ProblemBlock25",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      sliders: [
        "image-slider-problem-block-25-1.png",
        "image-slider-problem-block-25-2.png",
        "image-slider-problem-block-25-3.png",
        "image-slider-problem-block-25-4.png",
        "image-slider-problem-block-25-5.png",
      ],
    };
  },
  methods: {
    nextSlide() {
      this.swiper.slideNext();
    },
    prevSlide() {
      this.swiper.slidePrev();
    },
  },
  computed: {
    isBeginning() {
      if (this.swiper) {
        return this.swiper.isBeginning;
      } else {
        return false;
      }
    },
    isEnd() {
      if (this.swiper) {
        return this.swiper.isEnd;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.swiper = document.querySelector(".swiper").swiper;
    gsap.registerPlugin(ScrollTrigger);

    const content = this.$refs.content;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: content.querySelector(".message"),
        scroller: document.querySelector(".main-container"),
        start: "top 90%",
        end: "top 40%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      content.querySelector(".message"),
      { scale: 0.5, autoAlpha: 0 },
      {
        scale: 1,
        autoAlpha: 1,
        duration: 1,
        ease: "linear",
      },
      "-=0"
    );
  },
  unmounted() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content-block {
  max-width: 1050px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.slider-wrapper {
  max-width: 1050px;
  width: 100%;
  margin: 180px 160px 0 160px;
  position: relative;
  aspect-ratio: 2.0333;
}

.swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 65px;
  height: 65px;
  background-color: white;
  border-radius: 35px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.prev {
  left: -140px;
}

.prev-img {
  transform: rotate(180deg);
}

.next {
  right: -140px;
}

.disabled {
  opacity: 0.3;
}
</style>
