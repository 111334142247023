<template>
  <section class="wrapper" id="block-14">
    <div class="title-block">
      <h2 class="title my-15">
        Базовый стек GTS предназначен для управления облачными приложениями,
        обеспечивая надежность и безопасность в высоконагруженных системах.
      </h2>
      <h3 class="sub-title">
        Это значительно повышает эффективность разработки сложных систем,
        экономит до 90% серверного оборудования и сокращает тысячи
        человеко-часов на обслуживание<br />
        IT-инфраструктуры.
      </h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock14",
};
</script>

<style scoped>
.wrapper {
  margin: 150px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.wrapper::after {
  content: "";
  position: absolute;
  top: 30%;
  left: 15%;
  transform: translate(-50%, -50%);
  width: 200%;
  height: 200%;
  z-index: -1;
  background: radial-gradient(circle, #2da01a80, transparent 30%);
  filter: blur(100px);
}

.wrapper::before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 15%;
  transform: translate(50%, 50%);
  width: 200%;
  height: 200%;
  z-index: -1;
  background: radial-gradient(circle, #2da01a80, transparent 30%);
  filter: blur(100px);
}

.title-block {
  max-width: 1250px;
  width: 100%;
  padding: 0 20px;
  margin-top: 150px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 48px;
  font-weight: 550;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.sub-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
  max-width: 900px;
  width: 100%;
}
</style>
