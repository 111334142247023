<template>
  <section class="wrapper" id="block-2">
    <img
      src="@/assets/images/background-about-gts-block-2.png"
      alt="icon"
      class="background"
    />
    <div class="content">
      <h2 class="title">Что такое «GTS»</h2>
      <div class="slider-wrapper">
        <div
          class="swiper-btn prev"
          @click="prevSlide"
          :class="isBeginning ? 'disabled' : ''"
        >
          <img
            src="@/assets/icons/swiper-btn-icon.png"
            alt="prev"
            class="prev-img"
            :width="25"
          />
        </div>
        <div
          class="swiper-btn next"
          @click="nextSlide"
          :class="isEnd ? 'disabled' : ''"
        >
          <img
            src="@/assets/icons/swiper-btn-icon.png"
            alt="prev"
            class="next"
            :width="25"
          />
        </div>
        <Swiper
          ref="slider"
          :slides-per-view="3.5"
          :space-between="20"
          grabCursor
          class="swiper"
        >
          <SwiperSlide v-for="(slide, i) in slides" :key="i">
            <div class="slide">
              <h3 class="slide-title mb-5">
                <img
                  src="@/assets/icons/icon-green-snow.png"
                  alt="icon"
                  class="mr-3"
                />
                {{ slide.title }}
              </h3>
              <p class="slide-description">
                {{ slide.description }}
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "AboutGtsBlock2",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      swiper: null,
      slides: [
        {
          title: "01",
          description:
            "«GTS» обеспечивает необходимый уровень надежности и безопасности приложений при взаимодействии с различными видами терминальных устройств в высоконагруженных и масштабируемых системах массового обслуживания.",
        },
        {
          title: "02",
          description:
            "«GTS» позволяет вынести «за скобки» проблемы безопасности, масштабируемости и эластичности бизнес-процессов.",
        },
        {
          title: "03",
          description:
            "Решение “GTS” это ревизия и конвергенция функциональных возможностей инженерных решений, таких как IBM CICS Transaction Server, IBM MQ, Apache Kafka, IBM WebSphere Application Server, Tuxedo, Oracle Application Express, WebLogic, CORBA, Hadoop, Docker, Nginx в единую сквозную технологию разработки приложений end-to-end.",
        },
        {
          title: "04",
          description:
            "Cервер транзакций «GTS» устанавливается на серверах датацентров.",
        },
        {
          title: "05",
          description:
            "«GTS» и полный стек инструментальных средств (IDE, RAD) могут быть использованы как для масштабных корпоративных и государственных проектов, так и для построения систем для малого и среднего бизнеса.",
        },
        {
          title: "06",
          description:
            "Система управления очередями гарантированно фиксирует поток транзакций от терминалов и обеспечивает их дальнейшее обслуживание.",
        },
        {
          title: "07",
          description:
            "Диспетчер задач  “GTS” обеспечивает оптимальное выделение вычислительных ресурсов для минимизации среднего времени отклика в системе массового обслуживания. ",
        },
        {
          title: "08",
          description:
            "Реализуется адаптивная система распределения процессорного ресурса между множеством параллельно выполняемых задач.",
        },
        {
          title: "09",
          description:
            "Диспетчер задач “GTS” позволяет обслуживать процессы реального времени. Вы получите возможность мониторинга точных данных о бизнес-процессах. Это позволит улучшать корпоративную информационную среду, а также ляжет в основу дальнейшей автоматизации производства за счет на порядки более качественного обучения ИИ.",
        },
        {
          title: "10",
          description:
            "«GTS» в комплекте с автоматизированным местом программиста позволяет осуществлять разворачивание, обслуживание, управление изменениями, интеграцией, связанностью, масштабируемостью для высоконагруженных high-load систем массового обслуживания.",
        },
        {
          title: "11",
          description:
            "«GTS» – новый цифровой фундамент для Критической Информационной Инфраструктуры. Соответствует системе государственных требований к КИИ (Критическая Информационная Инфраструктура) и КВО (Критически Важный Объект).",
        },
      ],
    };
  },
  methods: {
    nextSlide() {
      this.swiper.slideNext();
    },
    prevSlide() {
      this.swiper.slidePrev();
    },
  },
  computed: {
    isBeginning() {
      if (this.swiper) {
        return this.swiper.isBeginning;
      } else {
        return false;
      }
    },
    isEnd() {
      if (this.swiper) {
        return this.swiper.isEnd;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.swiper = document.querySelector(".swiper").swiper;
  },
  unmounted() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-top: 160px;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.background {
  position: relative;
  z-index: -1;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 75px;
  letter-spacing: -0.2879999876022339px;
  text-align: center;
  margin-bottom: 90px;
}

.slider-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 150px;
  position: relative;
  z-index: 3;
}

.slider-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70px;
  pointer-events: none;
  z-index: 4;
  background: linear-gradient(to left, rgba(0, 0, 0, 1), transparent);
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 20px;
  background-color: #000;
  border-radius: 30px;
  border: 1px solid #ffffff33;
  max-width: 370px;
  width: 100%;
  height: 390px;
}

.slide-title {
  font-size: 32px;
  font-weight: 550;
  line-height: 32px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.slide-description {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: none;
}

.swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 35px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.prev {
  left: -100px;
}

.prev-img {
  transform: rotate(180deg);
}

.next {
  right: -100px;
}

.disabled {
  opacity: 0.3;
}
</style>
