<template>
  <section class="wrapper" id="block-13">
    <div class="title-block">
      <img
        src="@/assets/images/blur-background-title-problem-block-13.png"
        alt="background"
      />
      <h2 class="title">
        Рост TCO делает управление современными цифровыми активами дорогостоящим
        удовольствием.
      </h2>
    </div>
    <div class="card" ref="content">
      <div class="row">
        <div class="description">
          <img src="@/assets/images/gartner-logo.png" alt="gartner-logo" />
          <p class="text">
            Согласно последнему прогнозу Gartner, Inc., мировые расходы на ИТ в
            2024 году достигнут 5,06 трлн долларов США, что на 8% больше по
            сравнению с 2023 годом.
          </p>
        </div>
        <div class="about-block">
          <img
            src="@/assets/images/graphic-problem-block-13.png"
            alt="graphic-13"
            class="graphic"
          />
        </div>
      </div>
      <div class="divider-horizontal my-20" style="width: 100%"></div>
      <div class="row">
        <div class="description">
          <p class="text">
            Этот прогноз выше предыдущего квартального роста в 6,8% и указывает
            на то, что мировые ИТ-расходы могут превысить 8 трлн долларов США
            еще до конца десятилетия.
          </p>
        </div>
        <div class="about-block align-items-center flex-column animation">
          <img
            src="@/assets/images/background-card-problem-block13.png"
            alt="graphic-13-2"
            class="background"
          />
          <p class="sub-title">Расходы могут превысить</p>
          <h3 class="card-title my-5">$8 ТРЛН</h3>
          <p class="sub-title">До конца десятилетия</p>
        </div>
      </div>
      <a
        class="source-link no-uppercase"
        href="https://www.gartner.com/en/newsroom/press-releases/2024-04-16-gartner-forecast-worldwide-it-spending-to-grow-8-percent-in-2024"
        target="_blank"
        rel="noopener noreferrer"
      >
        Источник:
        <span class="underline">gartner.com</span>
      </a>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "ProblemBlock13",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const content = this.$refs.content;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: content,
        scroller: document.querySelector(".main-container"),
        start: "top 70%",
        end: "top -20%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });
    timeline.fromTo(
      content.querySelector(".graphic"),
      { clipPath: "inset(100% 0 0 0)" },
      {
        clipPath: "inset(0% 0 0 0)",
        duration: 1,
        ease: "linear",
      },
      "-=0"
    );

    timeline.fromTo(
      content.querySelector(".animation"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "=1"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.title-block {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 0;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  width: 100%;
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 0;
  border: 2px solid #333;
  border-radius: 40px;
  padding: 50px;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.description {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding-right: 20px;
  flex: 1 1 auto;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
  max-width: 90%;
}

.about-block {
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graphic {
  max-width: 270px;
  width: 100%;
}

.background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
  opacity: 0.5;
}

.card-title {
  font-size: 105px;
  font-weight: 550;
  line-height: 126px;
  letter-spacing: -0.4825px;
  text-align: center;
}

.source-link {
  position: absolute;
  right: 50px;
  top: 50px;
  opacity: 0.5;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
}
</style>
