<template>
  <section class="wrapper" id="block-24">
    <h2 class="title">Громкие случаи - только верхушка айсберга</h2>
    <div class="tile-row">
      <div class="white-card">
        <div class="btn-row">
          <button
            v-for="(btn, i) in buttons"
            :key="i"
            class="btn"
            :class="{ active: i === currentBtn }"
            @click="setCurrentIndex(i)"
          >
            {{ btn }}
          </button>
        </div>
        <h3 class="tile-title">
          <img
            class="icon mr-1"
            src="@/assets/icons/icon-black-snow.png"
            width="35"
            alt="icon"
          />
          {{ currentTailInfo.title }}
        </h3>
        <p class="card-description">
          {{ currentTailInfo.description }}
        </p>
      </div>
      <div class="black-card">
        <h3 class="card-title">{{ currentTailInfo.loss }}</h3>
        <p class="card-description">
          {{ currentTailInfo.subTitle }}
        </p>
        <a
          class="source-link no-uppercase"
          target="_blank"
          rel="noopener noreferrer"
          :href="`${currentTailInfo.link.url}`"
        >
          Источник:
          <span class="underline">{{ currentTailInfo.link.title }}</span>
        </a>
      </div>
    </div>
    <div class="content-block">
      <p class="text">все это - большие компании.</p>
      <p class="text my-5">Лидеры отрасли.</p>
      <p class="text">
        У них есть огромные резервы и миллионы на банковских счетах. Они могут
        пережить однодневный финансовый кризис...
      </p>
      <h3 class="sub-title my-20">
        но для малого бизнеса это будет смертельно опасно.
      </h3>
    </div>
    <div class="divider-horizontal"></div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock24",
  data() {
    return {
      currentBtn: 0,
      buttons: ["crowdstrike", "apple", "delta airlines", "facebook"],
      tailInfo: [
        {
          title: "Неудачное обновление Crowdstrike",
          description:
            "В июле 2024 года масштабные сбои вызвало одно обновление ПО Crowdstike. По всему миру было затронуто около 8,5 млн компьютеров, а также, почти 40 тысяч авиарейсов были остановлены.",
          link: {
            title: "lenta.ru",
            url: "https://lenta.ru/news/2024/07/19/uscherb-mirovoy-ekonomiki-ot-globalnogo-sboya-microsoft-otsenili/",
          },
          subTitle: "Потеряла мировая экономика",
          loss: "$500 млн",
        },
        {
          title: "остановка работы магазина на 12 часов",
          description:
            "В марте 2015 года магазин Apple прекратил работу на 12 часов. Это обошлось компании в 25 млн долларов США.",
          link: {
            title: "atlassian.com",
            url: "https://www.atlassian.com/ru/incident-management/kpis/cost-of-downtime",
          },
          subTitle: "Потеряли Apple за 12 часов простоя",
          loss: "$25 млн",
        },
        {
          title: "отключение электричества на 5 часов",
          description:
            "В августе 2016 года в одном операционном центре отключили электроэнергию на пять часов. Это привело к отмене 2000 рейсов и, по оценкам, к потере 150 млн долларов США авиакомпанией Delta Airlines.",
          link: {
            title: "atlassian.com",
            url: "https://www.atlassian.com/ru/incident-management/kpis/cost-of-downtime",
          },
          subTitle: "И 2,000 отмененных рейсов",
          loss: "$150 млн",
        },
        {
          title: "Прекращение работы сайта на 14 часов",
          description:
            "В марте 2019 года сайт Facebook прекратил работу на 14 часов. Тогда, согласно оценкам, компания потеряла 90 млн долларов США.",
          link: {
            title: "atlassian.com",
            url: "https://www.atlassian.com/ru/incident-management/kpis/cost-of-downtime",
          },
          subTitle: "Из-за неработающего сайта",
          loss: "$90 млн",
        },
      ],
    };
  },
  computed: {
    currentTailInfo() {
      return this.tailInfo[this.currentBtn];
    },
  },
  methods: {
    setCurrentIndex(index) {
      this.currentBtn = index;
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: -0.2879px;
  text-align: center;
  max-width: 800px;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 100px;
}

.content-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 100%;
  margin-top: 120px;
}

.text {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.sub-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.tile-row {
  display: flex;
  align-items: stretch;
  border: 2px solid #7e7e7e;
  border-radius: 40px;
  max-width: 1400px;
  width: 100%;
  margin: 0 20px;
  min-height: 430px;
  height: 100%;
}

.white-card {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 20px;
  padding: 24px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid #333;
  color: black;
}

.btn-row {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  border-radius: 20px;
  padding: 8px 20px;
  cursor: pointer;
  margin: 0 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.2879px;
  text-align: left;
  border: 2px solid #00000033;
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
  text-transform: uppercase;
}

.btn:hover {
  background-color: #00000033;
  color: #fff;
}

.active {
  background-color: #eb4a27;
  color: #fff;
}

.black-card {
  position: relative;
  flex-grow: 1;
  padding: 34px;
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("@/assets/images/image-backgrount-problen-tile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tile-title {
  font-size: 48px;
  font-weight: 550;
  line-height: 57px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.source-link {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  opacity: 0.5;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.card-title {
  font-size: 120px;
  font-weight: 550;
  line-height: 144px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card-description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: start;
  text-transform: none;
}
</style>
