<template>
  <section class="wrapper" ref="blockSection" id="block-20">
    <div class="d-flex align-items-center justify-content-between w-75 h-auto">
      <div class="left-side">
        <img
          class="brand-image"
          src="@/assets/icons/brand-icon.png"
          alt="brand"
        />
        <h2 class="title mb-5">
          Консорциум "Онтосеть"<br />
          - коллективный <br />
          заказчик для роста<br />
          вашего бизнеса
        </h2>
        <h3 class="sub-title">
          Мы создаем коллективного заказчика для <br />
          гармонизации интересов различных ниш, <br />
          превращая их требования в стандарты,<br />
          которые укрепят ваш бизнес.
        </h3>
      </div>
      <div class="right-side">
        <div class="tail">
          <img src="@/assets/images/image-block-20-background.png" alt="tail" />
          <router-link to="/about-gts">
            <img
              class="icon-tail"
              src="@/assets/icons/icon-go-to-white.png"
              alt="go-to"
            />
          </router-link>
          <h3 class="tail-title">
            Подробнее о<br />
            технологии GTS
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection20",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 70%",
        end: "top 40%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection,
      { autoAlpha: 0, scaleX: 1.5, rotateX: 45 },
      {
        autoAlpha: 1,
        scaleX: 1,
        rotateX: 0,
        duration: 1,
        ease: "linear",
      },
      "=-0.5"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.left-side {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  margin: 0 30px;
  padding: 35px 10px;
}

.brand-image {
  position: absolute;
  left: -55px;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.tail {
  position: relative;
}

.icon-tail {
  position: absolute;
  top: 15%;
  right: 10%;
}

.tail-title {
  position: absolute;
  bottom: 15%;
  left: 10%;
  font-size: 36px;
  font-weight: 550;
  line-height: 43px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}
</style>
