<template>
  <section class="wrapper" id="block-19">
    <img
      src="@/assets/images/background-problem-block-19.png"
      alt="background"
      class="background"
    />
  </section>
</template>

<script>
export default {
  name: "ProblemBlock19",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 150px;
  text-transform: uppercase;
}

.background {
  position: relative;
  z-index: -1;
}
</style>
