<template>
  <ModalComponent :isVisible="isModalVisible" @update:isVisible="closeModal">
    <div class="d-flex flex-column wrapper">
      <div
        class="d-flex align-items-center justify-content-between flex-shrink-0 align-items-stretch"
      >
        <img
          :src="require(`@/assets/images/${data.image}`)"
          alt="author"
          class="image"
        />
        <div class="name-container pl-5">
          <h2 class="name">
            <span class="multiline">{{ formattedName }}</span>
          </h2>
        </div>
        <div class="position-container pr-2">
          <h4 class="position">{{ data.position }}</h4>
        </div>
      </div>
      <div class="divider-horizontal w-100 my-4"></div>
      <div class="d-flex align-items-stretch">
        <div class="achievements flex-shrink-1 flex-grow-1 pr-2">
          <h4 class="title my-1">Достижения</h4>
          <p class="sub-title">
            {{ data.achievements }}
          </p>
        </div>
        <div class="education flex-shrink-1 flex-grow-1 pl-2">
          <h4 class="title my-1">Образование</h4>
          <p class="sub-title">
            {{ data.education }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-column mt-4">
        <h4 class="title my-1">Биография</h4>
        <p class="text mb-2" v-if="data.bio1">
          {{ data.bio1 }}
        </p>
        <p class="text" v-if="data.bio2">
          {{ data.bio2 }}
        </p>
        <p class="text my-2" v-if="data.bio3">
          {{ data.bio3 }}
        </p>
        <p class="text" v-if="data.bio4">
          {{ data.bio4 }}
        </p>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
export default {
  name: "ModalAboutAuthor",
  components: { ModalComponent },
  data() {
    return {
      isModalVisible: false,
      data: {},
    };
  },
  methods: {
    showModal(data) {
      window.locoScroll.stop();
      this.data = { ...data };
      console.log(this.data);
      this.isModalVisible = true;
    },
    closeModal() {
      window.locoScroll.start();
      this.isModalVisible = false;
    },
  },
  computed: {
    formattedName() {
      return this.data.name ? this.data.name.replace(/ /g, "\n") : "";
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 1250px;
  padding: 50px;
}

.image {
  max-width: 160px;
  width: 100%;
  max-height: 160px;
  height: auto;
  border-radius: 50%;
}

.name-container {
  flex-grow: 1;
  flex-shrink: 1;
}

.name {
  font-size: 48px;
  font-weight: 550;
  line-height: 58px;
  letter-spacing: -0.1939px;
  text-align: left;
  text-transform: uppercase;
}

.multiline {
  white-space: pre-line;
}

.position-container {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  flex-grow: 1;
}

.position {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.1939px;
  text-align: right;
  text-transform: uppercase;
  max-width: 80%;
}

.title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1939px;
  text-align: left;
  opacity: 0.5;
}

.sub-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 550;
  line-height: 24px;
  letter-spacing: -0.1939px;
  text-align: left;
}

.education {
  max-width: 50%;
}

.achievements {
  max-width: 50%;
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1939px;
  text-align: left;
}
</style>
