// main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/src/locomotive-scroll.scss";
import store from "./store";
import "locomotive-scroll/src/locomotive-scroll.scss";
import "@/style/index.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let locoScroll;

export function initLocoScroll() {
  locoScroll = new LocomotiveScroll({
    el: document.querySelector(".main-container"),
    smooth: true,
    multiplier: 1.4,
    reloadOnContextChange: true,
    touchMultiplier: 2,
    smoothMobile: 0,
    smartphone: {
      smooth: !0,
      breakpoint: 767,
    },
    tablet: {
      smooth: !1,
      breakpoint: 1024,
    },
    lerp: 0.11,
    getSpeed: true,
    getDirection: true,
    scrollbar: true,
  });

  ScrollTrigger.scrollerProxy(".main-container", {
    scrollTop(value) {
      return arguments.length
        ? locoScroll.scrollTo(value, 0, 0)
        : locoScroll.scroll.instance.scroll.y;
    },
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
    pinType: document.querySelector(".main-container").style.transform
      ? "transform"
      : "fixed",
  });

  locoScroll.on("scroll", () => {
    const scrollbar = document.querySelector(".c-scrollbar");
    const scrollbarThumb = document.querySelector(".c-scrollbar_thumb");
    const scrollTop =
      scrollbarThumb.getBoundingClientRect().top + window.scrollY + 1;
    scrollbar.style.setProperty("--scrollbar-height", `${scrollTop}px`);
  });

  locoScroll.on("scroll", ScrollTrigger.update);
  ScrollTrigger.refresh();

  window.addEventListener("load", () => {
    locoScroll.update();
  });

  window.locoScroll = locoScroll;
}

const app = createApp(App);
app.use(router).use(store);

app.mixin({
  mounted() {
    if (!locoScroll) {
      initLocoScroll();
    }
  },
  beforeUnmount() {
    if (locoScroll) {
      locoScroll.destroy();
    }
  },
});

router.afterEach(() => {
  if (locoScroll) {
    locoScroll.destroy();
  }
  setTimeout(() => {
    initLocoScroll();
    locoScroll.scrollTo(0, 0);
  }, 500);
});

app.mount("#app");
