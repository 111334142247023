import { createStore } from "vuex";

export default createStore({
  state: {
    burgerItems: [],
    authors: [
      {
        id: 1,
        name: "Китов Владимир Анатольевич",
        position1: "Главный ",
        position2: "консультант",
        position: "Главный консультант",
        image: "Kitov_Vladimir.png",
        top: "30",
        right: "65",
        scale: "1",
        achievements: 'Создатель телимонитора "ОБЬ"',
        education: "МГУ ИМ. ЛОМОНОСОВА",
        bio1: "В.А. Китов — автор системы ТСОП, языка программирования для обработки русскоязычных текстов, и руководитель проекта «Программное обеспечение системы КОСПАС» (система КОСПАС, входит в состав международного комплекса космического спасения КОСПАС/SARSAT). Под его руководством разработаны: ПО отраслевой сети МИНМОРФЛОТа, мультитерминальный монитор «ОБЬ», система информирования «ЛИСТЕР», система обмена данными МИНОБОРОНПРОМа и другие.",
        bio2: "С 1991 г. в течение 20 лет работал топ-менеджером в крупных российских и западных фирмах DEC (USA), SIEMENS (Germany), Техносерв (РФ), IBS (РФ), Fujitsu (Japan).",
        Bio3: "Выступал с докладами на международных конференциях и конгрессах в Австралии, Испании, Великобритании, Китае, США, Венгрии и Италии, а также с лекциями в университетах Циньхуа и Оборонных технологий НОАК в Китае.",
      },
      {
        id: 2,
        name: "Филиппова Валерия Вадимировна",
        position1: "Заместителя ",
        position2: "Председателя Совета",
        position:
          "Заместителя Председателя Совета - Ответственный секретарь Консорциума.",
        image: "Filipova_Valeria.png",
        top: "20",
        right: "34",
        scale: "0.8",
        achievements:
          "Организация программы критериального обеспечения деятельности страны, разработка композитного индекса счастья",
        education: "МГУ ИМ. ЛОМОНОСОВА",
        bio1: 'В. В. Филиппова - топ-менеджер, философ, онтологист и методолог , разработавшую уникальную технологию цивилизационно-ценностного управления. Опыт управленческой деятельности 22 года. Основное образование позволило занимать ведущие посты в банках, страховых организациях в части направления регионального развития и продаж, организовать Межрегиональную общественную организацию "Клуб политологов "Сфера"".',
        bio2: "Валерия Владимировна - специалист по стратегической коммуникации, один из авторов технологии народного целеполагания и целедостижения, инициатор проектов критериального обеспечения стратегического управления и реализации отраслевых политик. Вне работы не бывает.",
      },
      {
        id: 3,
        name: "Пастухов Александр Вадимирович",
        position1: "Председатель Совета",
        position2: "Консорциума",
        position: "Председатель Совета Консорциума",
        image: "Pastuhov_Aleksandr.png",
        top: "23",
        right: "1",
        scale: "0.7",
        education: "Рязанский государственный радиотехнический университет",
        achievements:
          "В 20 лет возглавил институт, провел антикризисное управление и добился аккредитации в Миннауки РФ.",
        bio1: "А.В. Пастухов — эксперт в управлении технологическими проектами и предприниматель. Генеральный директор Рязанского проектно-технологического института (1999-2001), разработал методику приоритизации проектов, автоматизировал маркетинг и технологические процессы, внедрил CRM и систему управления инженерными данными. Был председателем Совета директоров института (2003-2017) и заместителем по стратегическому развитию (2001-2017).",
        bio2: "В 2018 году как Project Manager в ЭПАМ Системз организовал проект по синхронизации данных сложных ИТ-систем. В 2020 году стал полуфиналистом конкурса “Лидеры России” в секции Финтех.",
      },
      {
        id: 4,
        name: "Чесноков Андрей Николаевич",
        position1: "Главный конструктор",
        position2: "Консорциума",
        position: "Главный конструктор Консорциума",
        image: "Chesnokov_Andrey.png",
        top: "38",
        right: "28",
        scale: "0.9",
        achievements: 'Создатель сервера транзакций "Baikonur"',
        education: "МГУ ИМ. ЛОМОНОСОВА",
        bio1: "А.Н. Чесноков — ключевая фигура в прикладной разработке и системном администрировании, продолжатель традиций отечественной школы Computer Science. Как редактор журнала “Цифровая экономика”, он активно участвует в цифровых инновациях. Его карьера включает руководство проектом «Байконур» в «Епсилон Технолоджис» (1996-2000), создание крупнейшего частного оператора спутниковой связи в СНГ, СТЭК.КОМ (2003-2008), работу в «НПК «Высокие технологии и стратегические системы» (2008-2010) и разработку системы управления для ПАО Русгидро в «ГидроИнжиниринг Сибирь» (2013-2015).",
        bio2: "С 2015 года Чесноков является сооснователем концепции Торгово-Промышленно-Финансового Интернета (ТПФИ), на основе которой был создан флагманский продукт GTS. Этот продукт объединяет различные цифровые объекты в мета-пространство (онтосферу), открывая новые возможности для сервисной экономики и индустриальных облаков.",
      },
      {
        id: 5,
        name: "Адрианов Александр Александрович",
        position1: "Заместитель ",
        position2: "Председателя Совета",
        position:
          "Заместитель Председателя Совета – Директор по стратегическому развитию",
        image: "Adrianov_Aleksandr.png",
        top: "38",
        right: "-3",
        scale: "0.65",
        achievements:
          "разработал и внедрил WMS-платформу по управлению цепочками поставок.",
        education: "МГУ ИМ. ЛОМОНОСОВА",
        bio1: "А.А. Андрианов — успешный предприниматель и основатель международной логистической компании Parcelpoint. Выпускник факультета государственного управления МГУ. В возрасте 21 года возглавил проект развития Яндекс.Такси, значительно расширив его региональное присутвие. В последние три года сосредоточился на создании международной WMS платформы для управления заказами и цепочками поставок.",
        bio2: "В качестве консультанта, организовывал открытие и сопровождение складов на территории США.",
        bio3: "Один из соавторов концепции выхода из глобального цивилиационного кризиса «Проект ЭРА». С августа 2024 года занимает пост главы молодежного совета Русского Космического Общества.",
      },
      {
        id: 6,
        name: "Ведута Елена Николаевна",
        position1: "научный руководитель",
        position2: "по экономической",
        position3: "кибернетике",
        position: "Научный руководитель по экономической кибернетике",
        image: "Veduta_Elena.png",
        top: "55",
        right: "22",
        scale: "0.72",
        achievements:
          "Книга «Socialism And Economic Cybernetics: Towards a Manifesto»",
        education: "МГУ ИМ. ЛОМОНОСОВА",
        bio1: "Е.Н. Ведута — ведущий российский экономист-кибернетик, доктор наук, профессор и академик Международной Академии менеджмента. Доктор экономических наук. Профессор. Заведующий кафедрой стратегического планирования и экономической политики факультета государственного управления МГУ им. М.В. Ломоносова.",
        bio2: "Она автор более 60 научных работ, посвящённых экономической кибернетике, политической экономии, государственной экономической стратегии, стратегическому планированию, экономической безопасности и интеграции межгосударственных блоков.",
        bio3: "Хранитель и продолжатель наследия Научной школы стратегического планирования Николая Ивановича Ведуты, чьи исследования были признаны Международным Биографическим Центром Кембриджа одними из выдающихся достижений ХХ века. Его школа объединяет теорию воспроизводства Маркса и Энгельса, кибернетику Винера и экономико-математическую модель Леонтьева, дополненные опытом Ведуты в управлении и создании первых автоматизированных систем управления в СССР.",
      },
      {
        id: 7,
        name: "Гурдус Александр Оскарович",
        position1: "Директор",
        position2: "по международным",
        position3: "стандартам",
        position: "Директор по международным стандартам",
        image: "Gurdus_Aleksandr.png",
        top: "53",
        right: "0",
        scale: "0.7",
        achievements:
          "разработал ПО для управления сетью «Ростелекома» и биллинг-системы для холдингов «Связьинвест» и «Ивтелеком».",
        education: "California Coast University",
        bio1: "А.О. Гурдус — кандидат технических наук, академик Международной Академии связи и обладатель докторской степени DBA California Coast University. Автор более 60 научных работ.",
        bio2: "С 1996 года руководил «РОСНО-Автополис», реализовал программу «Помощь на дорогах». Затем в «Телесофт» разработал ПО для «Ростелекома». В «РОСНО» автоматизировал урегулирование убытков и ввел новые страховые сервисы. В «Альянс» создал линейку страховых продуктов. С 2014 года возглавляет «21Компани» и «21Холдинг», объединив бизнес по производству и продаже одежды.",
        bio3: "С 2011 по 2014 год — член Правления и заместитель Генерального директора ОАО «Альянс», создал новую линейку страховых продуктов. С 2014 года — Президент группы компаний «21Компани» и Генеральный директор ООО «21Холдинг», где объединил бизнес по производству и продаже молодежной одежды в единую холдинговую структуру.",
      },
      {
        id: 8,
        name: "Кабилова Лола Наримановна",
        position1: "исполнительный",
        position2: "директор",
        position: "исполнительный директор",
        image: "Kabilova_Lola.png",
        top: "75",
        right: "20",
        scale: "0.82",
        achievements:
          "участие в возведении двух деревянных православных храмов.",
        education:
          "Московский энергетический институт (Национальный исследовательский университет)",
        bio1: "Л.Н. Кабилова - топ-менеджер и ведущий инженер с уникальным сочетанием технического мастерства и управленческого таланта. Более 25-ти лет профессионально занимается управлением некоммерческими организациями.",
        bio2: "Основное образование, полученное в Национальном исследовательском технологическом университете «МИСиС» и Московском энергетическом институте, позволило ей в свое время поработать в оборонном комплексе, а затем в таких крупных корпорациях как ГК «Интеррос», ИГ «Атон», ЗАО «Руссобалт» на позициях, связанных с управлением проектами и стратегической коммуникацией. ",
        bio3: "Легко находит общий язык с людьми и поддерживает долгосрочные профессиональные отношения. Своей ключевой компетенцией считает способность создавать и поддерживать командный дух взаимной поддержки и творческого поиска. На вопрос о наиболее важном достижении отвечает, что это – участие в возведении двух деревянных православных храмов. Вне работы увлекается подводным плаванием, горными лыжами и участием в поисковых экспедициях.",
      },
      {
        id: 9,
        name: "Голубовский Дмитрий Олегович",
        position1: "Финансовый советник ",
        position2: "Консорциума",
        position: "Финансовый советник Консорциума",
        image: "Golubovskiy_Dmitriy.png",
        top: "72",
        right: "-5",
        scale: "0.6",
        education: "МГТУ им. Н. Э. Баумана",
        achievements:
          "разработал ПО для управления сетью «Ростелекома» и биллинг-системы для холдингов «Связьинвест» и «Ивтелеком».",
        bio1: "Д.О. Голубовский - сертифицированный специалист российского финансового рынка (ФСФР 1.0).",
        bio2: "2009-2014 гг – ведущий аналитик инвестиционного холдинга «АТОН», аналитик казначейства холдинга, с 2011-го – аналитик buy side УК «АТОН-менеджмент». Управление финансовыми активами в периоды высокой турбулентности на финансовых рынках.",
        bio3: "2014-2017 гг – финансовый консультант финтех-компании «Copernicus Gold», архитектор финансовой логистики.",
        bio4: "С 2017-го года – независимый персональный финансовый советник для состоятельных портфельных инвесторов.",
      },
    ],
    cards: [
      {
        id: 1,
        title: "ПРОБЛЕМА №1",
        description: "Медленная адаптация",
        backgroundTail: "card-background-1.png",
        backgroundModal: "modal-background-1.png",
        width: 35,
        pain:
          "Скорость внесения изменений в бизнес-процессы сегодня упирается в возможности адаптации цифровой инфраструктуры.\n" +
          "Искусственный интеллект помогает корпорациям адаптироваться к изменениям рынка, но одновременно усугубляет неравенство для малого и среднего бизнеса, увеличивая контроль цифровых гигантов и создавая риски закрепления глобальных сверх-монополий. ",
        solution:
          "Технология GTS трансформирует IT, освобождая ресурсы для адаптации бизнес-процессов, позволяя малым и средним предприятиям конкурировать с крупными игроками через децентрализованную кибернетическую инфраструктуру и полный контроль над цифровыми данными.\n" +
          "Новый подход к разработке приложений, увеличивающий эффективность более чем в 10 раз, делает экономически оправданным переход на базовый стек GTS.",
      },
      {
        id: 2,
        title: "ПРОБЛЕМА №2",
        description: "Рост расходов",
        backgroundTail: "card-background-2.png",
        backgroundModal: "modal-background-2.png",
        width: 27,
        pain:
          "Затраты на владение цифровой инфраструктурой для малого и среднего бизнеса будут постоянно увеличиваться. Причины роста - увеличение расходов на поддержку, усложнение инфраструктуры, инфляция, рост цен на IT-услуги и необходимость обучения персонала способствуют этому.\n" +
          "Растущие затраты на владение делают управление цифровыми активами дорогостоящим, иногда сводя на нет выгоды от цифровизации компании.",
        solution:
          "GTS обеспечивает надежное и безопасное управление облачными приложениями, упрощая работу с высоконагруженными системами и критической инфраструктурой.\n" +
          "Разработка приложений с использованием единой сквозной технологии end-to-end на порядок упрощает развертывание и управление высоконагруженными системами, значительно снижая стоимость транзакций.\n" +
          "Конвергенция архитектур с GTS снизит затраты на серверы до 90%, ускорит обслуживание IT-инфраструктуры и упростит перенос приложений между различными Операционными Системами.",
      },
      {
        id: 3,
        title: "ПРОБЛЕМА №3",
        description: "Уязвимость инфраструктуры",
        backgroundTail: "card-background-3.png",
        backgroundModal: "modal-background-3.png",
        width: 32,
        pain:
          "Устаревшие IT-протоколы и стандарты, разработанные в 1980-х, не справляются с современными угрозами, что требует современных решений и усиливает риски, включая рост киберугроз.\n" +
          "Согласно отчету компании Cybersecurity Ventures, мировой ущерб от киберпреступности, который в 2021 году составил $6 трлн, прогнозируется к 2025 году вырасти до $10,5 трлн в год. Этот рост делает киберпреступность одной из наиболее серьезных экономических угроз в мире.",
        solution:
          "Мы отвергаем подход, предполагающий наложение очередных «заплаток» на устаревшие технологии. Путь GTS — это создание прочной основы, которая учитывает роль облачных технологий в индустрии и бизнесе, формируя надежную и устойчивую цифровую среду.\n" +
          "С использованием GTS вы сможете строить индустриальные облака для миллионов умных устройств, обеспечивая их надежную защиту и бесперебойную работу 24/7.",
      },
      {
        id: 4,
        title: "ПРОБЛЕМА №4",
        description: "остановка бизнес- процессов",
        backgroundTail: "card-background-4.png",
        backgroundModal: "modal-background-4.png",
        width: 30,
        pain:
          "Облачные решения, несмотря на предлагаемую гибкость и масштабируемость, не обеспечивают надежную защиту бизнеса, что подтверждается массовыми сбоями и утечками данных. \n" +
          "Отчет Verizon “2023 Data Breach Investigations Report” показывает рост инцидентов связанных с кибербезопасностью облачных сервисах из-за недостаточной защиты и конфигурационных ошибок.",
        solution:
          "GTS предназначен для управления различными облачными приложениями, обеспечивая их надежность и безопасность при работе с различными устройствами в системах с высокой нагрузкой и масштабировании. Это ключевые особенности для поддержания бесперебойной работы бизнеса и эффективного управления изменениями.\n" +
          "Линейно-масштабируемый кластер GTS обеспечивает отказоустойчивость управления маршрутизацией и идентификацией, позволяет обеспечивать работоспособность в момент наибольшей нагрузки. Система автоматически заменяет вышедшие из строя серверы и поддерживает непрерывную работу, реплицируя сессии пользователей.",
      },
      {
        id: 5,
        title: "ПРОБЛЕМА №5",
        description: "монополизация ниш агрегаторами",
        backgroundTail: "card-background-5.png",
        backgroundModal: "modal-background-5.png",
        width: 30,
        pain: "Малому и среднему бизнесу сложно конкурировать в создании масштабируемых цифровых сервисов из-за высоких затрат и нехватки специалистов. Крупные агрегаторы снижают расходы благодаря эффекту масштаба и контролируют рынок, захватывая ниши малого бизнеса. Искусственный интеллект усиливает их влияние, диктуя условия и маржинальность, что вынуждает компании участвовать в невыгодной для них конкуренции, выгодной только цифровым монополиям.",
        solution:
          "GTS значительно снижает порог входа для создания облачных приложений для малого и среднего бизнеса, обеспечивая им конкурентное преимущество и маневренность в борьбе за рынки с крупными агрегаторами.\n" +
          "Благодаря этому, компании смогут защитить свою нишу от цифровых гигантов, обеспечить безопасность бизнес-процессов и сохранить свои данные как исключительное конкурентное преимущество. С GTS ваши бизнес-механики и данные остаются исключительно в ваших руках.",
      },
      {
        id: 6,
        title: "ПРОБЛЕМА №6",
        description: "пренебрежение голосами малого и среднего бизнеса",
        backgroundTail: "card-background-6.png",
        backgroundModal: "modal-background-6.png",
        width: 34,
        pain:
          "Цифровые правила и стандарты часто разрабатываются с учетом интересов крупных компаний, что не учитывает потребности малого и среднего бизнеса. \n" +
          "Такие изменения правил создают непропорциональные финансовые и операционные нагрузки, так как изменение работающих цифровых систем сегодня является дорогим удовольствием, что ограничивает развитие малого и среднего бизнеса и его конкурентоспособность. \n" +
          "Недостаток внимания к интересам малого и среднего бизнеса также замедляет внедрение инновационных решений, критичных для вашего роста и успеха.",
        solution:
          "Команда GTS стремится создать коллективного заказчика для гармонизации интересов различных ниш, превращая их требования в стандарты, которые будут способствовать укреплению вашего бизнеса.",
      },
    ],
  },
  getters: {
    getBurgerItems(state) {
      return state.burgerItems;
    },
    getAuthors(state) {
      return state.authors;
    },
    getAuthorById(state) {
      return (id) => state.authors.find((author) => author.id === id);
    },
    getCards(state) {
      return state.cards;
    },
    getCardById(state) {
      return (id) => state.cards.find((card) => card.id === id);
    },
  },
  mutations: {
    setBurgerItems(state, items) {
      state.burgerItems = items;
    },
  },
  actions: {},
  modules: {},
});
