<template>
  <section class="wrapper" ref="blockSection" id="block-13">
    <img src="@/assets/images/image-block-13.png" alt="tail" class="tail-img" />
    <h3 class="sub-title mt-15 outlined-green">преимущество №3</h3>
    <h2 class="title my-15">
      Единое<br />
      пространство <br />
      приложений<br />
      на базе GTS
    </h2>
    <p class="content">
      На базе GTS будут созданы интегрированные приложения,<br />
      обеспечивающие бесшовное взаимодействие и простое<br />
      подключение устройств, например, чайника, к<br />
      банковскому счету вашей компании. Идеальное решение<br />
      для сервисной экономики
    </p>
    <div
      class="divider-vertical"
      style="height: 140px; margin-top: 120px"
    ></div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection13",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 20%",
        end: "top -90%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".tail-img"),
      { autoAlpha: 0, scale: 1.5 },
      { autoAlpha: 1, scale: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".sub-title"),
      { scale: 1.5, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "=2"
    );

    timeline.fromTo(
      blockSection.querySelector(".title"),
      { scaleY: 0.2, autoAlpha: 0 },
      { scaleY: 1, autoAlpha: 1, duration: 1 },
      "=2"
    );

    timeline.fromTo(
      blockSection.querySelector(".content"),
      { y: 100, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 1 },
      "=2"
    );

    timeline.fromTo(
      blockSection.querySelector(".divider-vertical"),
      { height: 1 },
      { height: 140, duration: 1 },
      "=2"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.sub-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.content {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
