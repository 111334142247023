<template>
  <section class="wrapper" id="block-1">
    <h4 class="section-title outlined-white mb-4">ПРОБЛЕМА №1</h4>
    <h3 class="section-sub-title mb-15">проблема адаптации</h3>
    <h2 class="title">
      Цифровые технологии<br />
      связали мир.
    </h2>
    <div class="divider-vertical" style="height: 115px; margin: 150px 0"></div>
    <h3 class="sub-title mb-180">
      Кризисы и санкции<br />
      ускорили изменения в<br />
      бизнес-среде<br />
      <span class="outlined-white">критически.</span>
    </h3>
    <div class="block-section-tail">
      <h3 class="block-title">
        Тренд на цифровизацию породил<br />
        больше проблем, чем способен решить<br />
        с текущим набором IT-технологий.
      </h3>
      <div class="tile-container">
        <h3 class="sub-title">
          70% цифровых трансформаций не достигают своих целей
        </h3>
        <p class="section-title no-uppercase mt-15 mb-10">
          Согласно исследованию McKinsey, из-за недостаточной гибкости
          существующих решений и нехватки квалифицированных кадров.
        </p>
        <a
          class="source-link no-uppercase"
          href="https://pro.rbc.ru/demo/615c08c49a794751c17e20f8"
        >
          Источник:
          <span class="underline">rbc.ru</span>
        </a>
      </div>
    </div>
    <div class="block-section mt-15">
      <h3 class="section-sub-title">
        Доступные IT-решения
        <span class="outlined-white">не способны адекватно</span><br />
        <span class="outlined-white">реагировать</span>
        на быстрые изменения в бизнес-<br />
        среде. Проблема не в ограниченных бюджетах<br />
        вашей компании, а в недостаточной гибкости и<br />
        адаптивности существующих технологий
      </h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock1",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.mb-180 {
  margin-bottom: 180px;
}

.section-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.section-sub-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.sub-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.block-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.block-section-tail {
  background-image: url("@/assets/images/problem-tile-background.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center calc(50% - 80px);
}

.tile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #212121;
  border-radius: 40px;
  padding: 45px 50px;
  max-width: 1150px;
  width: 100%;
  margin: 60px 20px;
}

.source-link {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  opacity: 0.5;
}

.block-section {
  max-width: 1000px;
  width: 100%;
}
</style>
