<template>
  <section class="wrapper" ref="blockSection" id="block-9">
    <img
      src="@/assets/images/image-block-9-layer.png"
      alt="flashing image"
      class="flash"
    />
    <div class="hide"></div>
    <div class="content-block">
      <h2 class="title mb-10">
        <span class="green">GTS</span> – когда перемены<br />
        превращаются в<br />
        вашу прибыль.
      </h2>
      <h2 class="title">
        Преуспей в эпоху<br />
        перемен.
      </h2>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection9",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 50%",
        end: "top 0%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });
    timeline.fromTo(
      blockSection.querySelectorAll(".title"),
      { scale: 1.2, autoAlpha: 0, translateY: 50 },
      { scale: 1, autoAlpha: 1, translateY: 0, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".flash"),
      { scale: 0.5, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 2 },
      "=2"
    );

    timeline.fromTo(
      blockSection.querySelector(".hide"),
      { translateX: 0 },
      { translateX: "100%", duration: 6 },
      "=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  padding: 250px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  background-image: url("@/assets/images/image-block-9.png");
  background-size: cover; /* Изображение будет покрывать всю область */
  background-position: center calc(50% - 100px); /* Центрирование изображения */
  background-repeat: no-repeat;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.content-block {
  position: relative;
  z-index: 3;
}

.flash {
  position: absolute;
  z-index: 2;
}

.hide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 1;
}
</style>
