<template>
  <section class="wrapper" id="block-16">
    <div class="content-block">
      <h4 class="section-title outlined-white mb-4">ПРОБЛЕМА №3</h4>
      <h3 class="section-sub-title mb-15">РОСТ КИБЕРПРЕСТУПНОСТИ</h3>
      <h2 class="title mt-10">
        Если Вас<br />
        не взломали,<br />
        это не значит,<br />
        что Вас<br />
        не взламывают
      </h2>
      <div class="title white-tile mt-2">
        <span class="red-circle"></span> прямо сейчас
      </div>
    </div>
    <div class="card">
      <div class="card-content">
        <h4 class="card-title">
          Ущерб от кибератак на сегодняшний день превышает 10% мирового ВВП, а
          его объём в 2 раза выше бюджета всей отрасли цифровых технологий.
        </h4>
      </div>
      <div class="card-action">
        <img
          src="@/assets/images/graphic-problem-block-16.png"
          alt="graphic-problem"
          class="graphic"
        />
      </div>
      <a
        class="source-link no-uppercase"
        href="https://cybersecurityventures.com/hackerpocalypse-cybercrime-report-2016/#:~:text=Cybersecurity%20Ventures%20expects%20global%20cybercrime,%243%20trillion%20USD%20in%202015."
        target="_blank"
        rel="noopener noreferrer"
      >
        Источник:
        <span class="underline">cybersecurityventures.com</span>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock16",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content-block {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.section-sub-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 73px;
  font-weight: 700;
  line-height: 87px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.white-tile {
  background-color: white;
  padding: 7px 18px;
  border-radius: 50px;
  color: #000000;
}

.red-circle {
  display: inline-block;
  width: 54px;
  height: 54px;
  background-color: #eb4a27;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.6;
    transform: scale(0.97);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

.card {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  border: 2px solid #333;
  border-radius: 40px;
  padding: 50px;
  margin-top: 150px;
}

.card-content {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-title {
  font-size: 36px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: none;
  max-width: 85%;
}

.graphic {
  max-width: 530px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.card-action {
  display: flex;
  justify-content: end;
  align-items: end;
  width: 60%;
}

.source-link {
  position: absolute;
  right: 40px;
  top: 40px;
  opacity: 0.5;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
}
</style>
