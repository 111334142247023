<template>
  <div ref="videoContainer" class="video-wrapper">
    <video ref="videoElement" :src="videoSrc" class="video" muted></video>
  </div>
</template>

<script>
export default {
  name: "HeroVideo",
  props: {
    videoSrc: {
      type: String,
      required: true,
    },
    startPosition: {
      type: Number,
      default: 0,
    },
    speed: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      videoElement: null,
      isVideoEnded: false,
      scrollTimeout: null,
      isPlaying: false,
      playbackSpeed: this.speed,
    };
  },
  mounted() {
    this.videoElement = this.$refs.videoElement;
    this.initializePlayback();

    window.addEventListener("wheel", this.handleWheel, { passive: false });

    this.videoElement.addEventListener("ended", this.handleVideoEnd);
    this.videoElement.addEventListener("canplay", this.handleCanPlay);
  },
  beforeUnmount() {
    window.removeEventListener("wheel", this.handleWheel);
    this.videoElement.removeEventListener("ended", this.handleVideoEnd);
    this.videoElement.removeEventListener("canplay", this.handleCanPlay);
  },
  methods: {
    initializePlayback() {
      if (this.startPosition > 0) {
        this.videoElement.currentTime = this.startPosition / 1000;
      } else {
        window.addEventListener("wheel", this.handleWheel, { passive: false });
      }
    },

    handleWheel(event) {
      event.preventDefault();

      if (!this.isPlaying && event.deltaY > 0) {
        this.playVideo();
      }

      if (event.deltaY < 0) {
        this.playbackSpeed = Math.max(this.playbackSpeed - 0.1, 0.5);
        this.$emit("wheelUp");
      } else if (event.deltaY > 0) {
        this.playbackSpeed = Math.min(this.playbackSpeed + 0.1, 3.0);
        this.$emit("wheelDown");
      }

      this.videoElement.playbackRate = this.playbackSpeed;
    },

    playVideo() {
      if (!this.isPlaying) {
        this.videoElement.playbackRate = this.playbackSpeed;
        this.videoElement.play();
        this.isPlaying = true;
      }
    },

    handleVideoEnd() {
      this.isVideoEnded = true;
      this.$emit("videoEnd");
      window.removeEventListener("wheel", this.handleWheel);
    },

    handleCanPlay() {
      this.$emit("loadingVideoFinish");
    },
  },
};
</script>

<style scoped>
.video-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh;
}

.video {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
