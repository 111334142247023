<template>
  <section class="wrapper" id="block-3" ref="content">
    <div class="content">
      <img
        src="@/assets/images/digital-graphic-background.png"
        alt="graphic-background"
      />
      <img
        src="@/assets/images/digital-graphic-green-line.png"
        alt="graphic-green-line"
        class="line green-line"
      />
      <img
        src="@/assets/images/digital-graphic-red-line.png"
        alt="graphic-red-line"
        class="line red-line"
      />
      <img
        src="@/assets/images/digital-graphic-point.png"
        alt="graphic-point"
        class="line point"
      />
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "ProblemBlock3",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const content = this.$refs.content;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: content,
        scroller: document.querySelector(".main-container"),
        start: "top 30%",
        end: "+=100%",
        scrub: 0.5,
        pin: true,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      content.querySelector(".red-line"),
      { clipPath: "inset(0 100% 0 0)" },
      {
        clipPath: "inset(0 0% 0 0)",
        duration: 2,
        ease: "linear",
      },
      "-=0"
    );

    timeline.fromTo(
      content.querySelector(".green-line"),
      { clipPath: "inset(0 100% 0 0)" },
      {
        clipPath: "inset(0 0% 0 0)",
        duration: 1,
        ease: "linear",
      },
      "-=0"
    );

    timeline.fromTo(
      content.querySelector(".point"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 },
      "-=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.content {
  position: relative;
  z-index: 1;
  margin: 0 20px 150px;
}

.line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
</style>
