<template>
  <section class="wrapper" id="block-6">
    <h2 class="title">
      С 2015 года наша команда искала государственную поддержку для старта
      инициативы
    </h2>
    <h3 class="sub-title mt-20">
      В то время проблема цифровой независимости не была столь актуальна, а Open
      Source решения казались более привлекательными и давали быстрые
      экономические эффекты.
    </h3>
    <div
      class="divider-vertical"
      style="height: 225px; margin-top: 150px; margin-bottom: -150px"
    ></div>
    <div class="title-block">
      <img
        src="@/assets/images/blur-about-gts-block-6.png"
        alt="background"
        class="background"
      />
      <h2 class="title-blur">
        ВРЕМЯ БЫСТРЫХ ЭФФЕКТОВ ЗАКОНЧИЛОСЬ. НАСТУПИЛ ЧАС КРОПОТЛИВОЙ РАБОТЫ
      </h2>
    </div>
    <div
      class="divider-vertical"
      style="height: 225px; margin-top: -150px; margin-bottom: 150px"
    ></div>
    <h2 class="title">
      После долгих усилий команда GTS решила запуститься как бизнес-проект
    </h2>
    <h3 class="sub-title my-20">
      в 2023 году завершена упаковка концепции и<br />
      инвестиционного плана, в 2024 году создан<br />
      консорциум для формирования<br />
      коллективного заказчика.
    </h3>
    <h3 class="sub-title">
      В условиях очевидных проблем<br />
      цифровых технологий настало время<br />
      пересмотреть привычные подходы.<br />
      технология "GTS" становится<br />
      стратегически важным для выживания бизнеса.
    </h3>
  </section>
</template>

<script>
export default {
  name: "AboutGtsBlock6",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 75px;
  letter-spacing: -0.2879px;
  text-align: center;
  max-width: 1200px;
  width: 100%;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
  max-width: 800px;
  width: 100%;
}

.title-block {
  position: relative;
}

.background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.title-blur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 550;
  line-height: 77px;
  letter-spacing: -0.2879px;
  text-align: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 40px;
}
</style>
