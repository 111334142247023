import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutProblemView from "../views/AboutProblemView.vue";
import AboutGtsView from "../views/AboutGtsView.vue";
import ContactsView from "../views/ContactsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about-problem",
    name: "about-problem",
    component: AboutProblemView,
  },
  {
    path: "/about-gts",
    name: "about-gts",
    component: AboutGtsView,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: ContactsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
});

export default router;
