<template>
  <div class="page">
    <HeroBlock v-if="!showContent" @videoEnd="startContent" />
    <ContentBlock
      v-if="showContent"
      @revert="
        () => {
          this.showContent = false;
        }
      "
    />
  </div>
</template>

<script>
import ContentBlock from "@/components/HomeView/ContentBlock.vue";
import HeroBlock from "@/components/HomeView/HeroBlock.vue";
import eventBus from "@/utils/eventsBus";

export default {
  name: "HomeView",
  components: {
    HeroBlock,
    ContentBlock,
  },
  data() {
    return {
      showContent: false,
    };
  },
  methods: {
    startContent() {
      this.showContent = true;
      window.locoScroll.update();
    },
  },
  mounted() {
    eventBus.emit("loadingTrigger", true);
  },
};
</script>

<style></style>
