<template>
  <section class="wrapper" id="block-17" ref="content">
    <img
      src="@/assets/images/graphic-problem-block-17.png"
      alt="graphic"
      class="graphic"
    />
    <div class="content-block">
      <div class="description">
        <p class="text">
          Кибер-безопасность сегодня – опасная
          <span class="outlined-white px-2 py-0">иллюзия</span> в мире бизнеса.
          Высокие затраты без гарантированной защиты создают ощущение дорогой,
          но неэффективной статьи расходов.
        </p>
        <p class="text">
          Одного успешного случая атаки достаточно, чтобы причинить непоправимый
          ущерб вашему бизнесу.
        </p>
      </div>
    </div>
    <a
      class="source-link no-uppercase"
      href="https://cybersecurityventures.com/hackerpocalypse-cybercrime-report-2016/#:~:text=Cybersecurity%20Ventures%20expects%20global%20cybercrime,%243%20trillion%20USD%20in%202015."
      target="_blank"
      rel="noopener noreferrer"
    >
      Источник:
      <span class="underline">cybersecurityventures.com</span>
    </a>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "ProblemBlock17",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const content = this.$refs.content;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: content,
        scroller: document.querySelector(".main-container"),
        start: "top 10%",
        end: "+=100%",
        scrub: 0.1,
        pin: true,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });
    timeline.fromTo(
      content.querySelector(".graphic"),
      { clipPath: "inset(0 100% 0 0)" },
      {
        clipPath: "inset(0 0% 0 0)",
        duration: 1,
        ease: "linear",
      },
      "-=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.graphic {
  position: relative;
  z-index: -1;
  width: 100%;
  margin-bottom: 150px;
}

.content-block {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
}

.description {
  max-width: 70%;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.28799px;
  text-align: left;
  margin: 20px auto;
  text-transform: none;
}

.source-link {
  position: absolute;
  right: 40px;
  bottom: 0;
  opacity: 0.5;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
}
</style>
