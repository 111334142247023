<template>
  <section class="wrapper" id="block-27">
    <div class="content">
      <p class="text">
        Монополизация IT-индустрии приводит к тому, что большинство игроков
        рынка перестают иметь право голоса на определение своей маржинальности.
      </p>
      <h2 class="title">
        Защити<br />
        свою нишу<br />
        в будущем.
      </h2>
      <p class="text">
        Вступи в консорциум ОНТОСЕТЬ сегодня – получи определяющее конкурентное
        преимущество первым и реши вопрос существования своего бизнеса в
        условиях наступившей тотальной информационной войны.
      </p>
      <p class="text outlined-white mt-10">Места в консорциуме ограничены.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock27",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}
.content {
  max-width: 820px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 73px;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: -0.2879999876022339px;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 60px;
}
</style>
