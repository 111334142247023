<template>
  <section class="wrapper" id="block-7">
    <div class="content">
      <div class="title-block">
        <img
          src="@/assets/images/title-background-problem-block-7.png"
          alt="background"
        />
        <h2 class="title">
          базовый<br />
          технологический<br />
          стек gts это
        </h2>
      </div>
      <div class="card-container">
        <div class="card" v-for="(card, i) in cards" :key="i">
          <h3 class="card-title" :style="`border-color: ${card.color}`">
            {{ card.title }}
          </h3>
          <p class="card-description">{{ card.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock7",
  data() {
    return {
      cards: [
        {
          title: "Эффективность",
          description:
            'Технология "GTS" высвобождает тысячи человеко-часов прикладной разработки, позволяя направить ресурсы на адаптацию бизнес-процессов под текущие требования.',
          color: "#0080FF",
        },
        {
          title: "гибкость",
          description:
            '"GTS" предоставляет малому и среднему бизнесу гибкость в управлении изменениями, предлагая передовую среду, которая концептуально превосходит существующие IT-решения лидеров рынка.',
          color: "#2DA01A",
        },
        {
          title: "все для роста вашего бизнеса",
          description:
            "Мы обеспечиваем безопасность, масштабируемость и эластичность, предлагая решение для роста вашего бизнеса. Единая инфраструктура дает вам независимость от крупных платформ.",
          color: "#FD7B01",
        },
        {
          title: 'Принцип "нулевого доверия"',
          description:
            "Механизм децентрализации и шифрования исключает несанкционированный доступ к данным. Принцип “нулевого доверия” создает открытые цифровые стандарты будущего.",
          color: "#FA9883",
        },
      ],
    };
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content {
  max-width: 1200px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-block {
  position: relative;
  z-index: 1;
  margin-bottom: -200px;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  z-index: 2;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  max-width: calc(50% - 20px);
  min-height: 250px;
  border-radius: 30px;
  border: 1px solid #333;
}

.card-title {
  padding: 25px;
  border: 1px solid;
  border-radius: 30px;
  font-size: 32px;
  font-weight: 550;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: left;
  width: 100%;
}

.card-description {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  padding: 45px 20px 20px 20px;
}
</style>
