<template>
  <div
    class="wrapper-tail py-4 px-4"
    :style="`position: absolute; top:${data.top}%; right:${data.right}%; scale: ${data.scale}`"
  >
    <div class="img-block">
      <img
        :src="require(`@/assets/images/${data.image}`)"
        alt="author"
        class="image"
      />
    </div>
    <div class="content">
      <h2 class="name">
        <span class="multiline">{{ formattedName }}</span>
      </h2>
      <p class="position mt-2">
        {{ data.position1 }}
      </p>
      <p class="position">
        {{ data.position2 }}
      </p>
      <p class="position" v-if="data.position3">
        {{ data.position3 }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthorTail",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    formattedName() {
      return this.data.name ? this.data.name.replace(/ /g, "\n") : "";
    },
  },
};
</script>

<style scoped>
.wrapper-tail {
  border: 1px solid #696b69;
  background-color: #00000033;
  backdrop-filter: blur(9px);
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  cursor: pointer;
}

.img-block {
  height: 100%;
  overflow: hidden;
}

.image {
  border-radius: 100%;
  width: 126px;
  height: auto;
}

.content {
  padding-left: 20px;
  padding-right: 70px;
  flex: 1;
}

.name {
  font-size: 22px;
  font-weight: 550;
  line-height: 26px;
  letter-spacing: -0.1939px;
  text-align: left;
}

.position {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.1939px;
  text-align: left;
}

.multiline {
  white-space: pre-line;
}
</style>
