<template>
  <section class="wrapper" id="creeping">
    <img
      src="@/assets/images/creeping-lines.png"
      alt="creeping"
      class="creeping"
    />
  </section>
</template>

<script>
export default {
  name: "CreepingTemporary",
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.creeping {
  object-fit: contain;
  width: 100%;
}
</style>
