<template>
  <section class="wrapper" id="block-20">
    <div class="content-block">
      <h3 class="sub-title">
        Риск ущерба от внедрения современных IT технологий выше, чем выгода от
        полученных эффектов.
      </h3>
      <h3 class="sub-title mt-5">
        Само защитное ПО представляет собой поверхность для атаки.
      </h3>
    </div>
    <div class="content-block">
      <p class="text mt-150 mb-20">
        Базовый стек технологий «GTS» позволяет превратить поверхность атаки в
        точку за счет конвергенции большого количества технологий в компактное и
        эффективное решение, состоящее из источника данных и сервера транзакций.
        Это
        <span class="outlined-green py-1">радикально снизит</span> поверхность
        атаки.
      </p>
    </div>
    <h2 class="title my-20">
      ваш безопасник<br />
      <span class="red">(НЕ)</span> в состоянии<br />
      все решить
    </h2>
    <div class="content-block mt-20">
      <p class="text">
        Квалификация хакеров превосходит базовые знания<br />
        большинства IT-специалистов, а средства нападения<br />
        постоянно совершенствуются, опережая средства защиты.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock20",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.content-block {
  max-width: 800px;
  width: 100%;
}

.sub-title {
  font-size: 32px;
  font-weight: 550;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.image-inside {
  width: 100%;
  margin: 150px 0;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.mt-150 {
  margin-top: 150px;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 73px;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
