<template>
  <section class="wrapper" id="block-4">
    <img
      src="@/assets/images/background-about-gts-block-4.png"
      alt="background"
      class="background"
    />
    <div class="content">
      <h2 class="title">
        История вопроса технологии “GTS” насчитывает более 50 лет детальной
        проработки
      </h2>
      <p class="description mt-20">
        Всем этим занимались лучшие умы<br />
        отечественной школы кибернетики.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutGtsBlock4",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: -24%;
  margin-top: -23%;
  text-transform: uppercase;
  pointer-events: none;
}

.background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 1200px;
  width: 100%;
  padding: 20px;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 73px;
  font-weight: 700;
  line-height: 87px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
