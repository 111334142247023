<template>
  <section class="wrapper" ref="blockSection" id="block-14">
    <div class="tail">
      <img
        src="@/assets/images/image-block-14-icon.png"
        alt="icon"
        class="icon"
      />
      <h2 class="title mb-10">
        Все это –<br />
        автоматизирован<br />ное рабочее место<br />
        разработчика
      </h2>
      <p class="description mb-10">
        Cобранные в IDE и RAD среду и реализующую мощный<br />
        потенциал базового стека технологии GTS.
      </p>
    </div>
    <img
      class="window-img"
      src="@/assets/images/image-block-14-window.png"
      alt="window"
    />
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection14",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 20%",
        end: "top -80%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".tail"),
      { autoAlpha: 0, scale: 0.5 },
      { autoAlpha: 1, scale: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".icon"),
      { autoAlpha: 0, scale: 0.5 },
      { autoAlpha: 1, scale: 1, duration: 1 },
      "=1"
    );

    timeline.fromTo(
      blockSection.querySelector(".title"),
      { scaleY: 0.2, autoAlpha: 0 },
      { scaleY: 1, autoAlpha: 1, duration: 1 },
      "=3"
    );

    timeline.fromTo(
      blockSection.querySelector(".description"),
      { scale: 1.5, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "=2"
    );

    timeline.fromTo(
      blockSection.querySelector(".window-img"),
      { y: 200, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 1 },
      "=3"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  padding-bottom: 750px;
}

.tail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("@/assets/images/image-block-14.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1033px;
  padding-bottom: 100px;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.window-img {
  position: absolute;
  bottom: -100px;
}
</style>
