<template>
  <section class="wrapper" id="block-9">
    <div class="title-block">
      <img
        src="@/assets/images/title-background-problem-block-9.png"
        alt="background"
      />
      <div class="title-content">
        <h2 class="title mb-15">
          Технология «GTS» –<br />
          коренная смена подходов в IT.
        </h2>
        <h3 class="sub-title">
          Это конвергенция лучших современных<br />
          решений, основанных на отечественной<br />
          школе кибернетики.
        </h3>
      </div>
    </div>
    <div class="content-block">
      <h2 class="title mb-15">
        Главное отличие – <br />
        в подходе
      </h2>
      <h3 class="sub-title">
        Мы решаем проблемы цифровизации с помощью<br />
        передовых кибернетических технологий, а не<br />
        зарабатываем на устаревших методах.
      </h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock9",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.title-block {
  position: relative;
  z-index: 1;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 0;
}

.title-content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  width: 100%;
  z-index: 2;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.sub-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.content-block {
  margin-top: 150px;
  max-width: 1000px;
  width: 100%;
}
</style>
