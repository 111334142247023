<template>
  <section class="wrapper" ref="blockSection" id="block-10">
    <h3 class="sub-title mb-10">ДЛЯ ЧЕГО НУЖЕН GTS?</h3>
    <h2 class="title">
      <span class="green">GTS</span> – источник<br />
      долгосрочных<br />
      конкурентных<br />
      преимуществ
    </h2>
    <div class="divider-vertical" style="height: 140px; margin: 120px 0"></div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection10",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 90%",
        end: "top 20%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".sub-title"),
      { autoAlpha: 0, translateY: -100 },
      { autoAlpha: 1, translateY: 0, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".title"),
      { scaleY: 0.2, scaleX: 2, autoAlpha: 0 },
      { scaleY: 1, scaleX: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".divider-vertical"),
      { height: 1 },
      { height: 140, duration: 1 },
      "=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  text-align: center;
}
</style>
