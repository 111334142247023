<template>
  <section class="wrapper" id="block-3">
    <h2 class="title mb-20">
      принцип<br />
      «нулевого доверия»
    </h2>
    <h3 class="sub-title">
      При разработке «GTS» используется принцип «нулевого<br />
      доверия»: любой элемент системы не доверяет никому, даже<br />
      себе. Нетерпимость в бэк-дорам и «закладкам» - ключевая<br />
      часть нашей политики безопасности и культуры доверия.
    </h3>
    <div class="card">
      <div class="left-side">
        Технология «GTS» готова к серийному выпуску и не несет значительных
        инвестиционных рисков, так как является четвертой итерацией ранее
        созданных продуктов на базе отечественной школы Computer Sience.
      </div>
      <div class="right-side">
        <h4 class="card-title">УГТ9</h4>
        <p class="card-sub-title">
          Продемонстрирована работа реальной системы в условиях реальной
          эксплуатации. Технология подготовлена к серийному производству
        </p>
        <a
          class="source-link no-uppercase mt-4"
          target="_blank"
          rel="noopener noreferrer"
          href="https://digital.gov.ru/uploaded/files/urovni-gotovnosti-tehnologii-gost-58048-2017.pdf"
        >
          Источник:
          <span class="underline">gov.ru</span>
        </a>
      </div>
    </div>
    <div class="divider-horizontal mb-0"></div>
  </section>
</template>

<script>
export default {
  name: "AboutGtsBlock3",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  background-image: url("@/assets/images/blur-about-gts-block-3.png");
  background-repeat: no-repeat;
  background-position: calc(50% + 250px) calc(50% + 200px);
  background-size: contain;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 73px;
  font-weight: 550;
  line-height: 88px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card {
  max-width: 1200px;
  margin: 300px 20px 0 20px;
  width: 100%;
  padding: 50px;
  border: 2px solid #333;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 30px;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.right-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}

.card-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 148px;
  font-weight: 700;
  line-height: 178px;
  text-align: center;
}

.card-sub-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.source-link {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  opacity: 0.5;
}
</style>
