<template>
  <section class="wrapper" id="block-2">
    <div class="content">
      <img
        src="@/assets/images/image-tile-first-problem.png"
        alt="first problem"
        ref="image"
      />
      <h2 class="title">
        Даже крупные корпорации с<br />
        большими бюджетами теряют<br />
        рынки, не справившись с<br />
        изменениями среды.
      </h2>
      <div class="tile">
        <p class="description">
          Наиболее уязвимыми являются малый и<br />
          средний бизнес, <span class="outlined-white">не обладающие</span
          ><br />
          <span class="outlined-white">достаточными ресурсами</span> для
          адаптации.<br />
          Возрастают риски потери<br />
          конкурентоспособности и устойчивости,<br />
          оказавшись под угрозой выживания
        </p>
        <h3 class="tile-title mt-15">один на один.</h3>
        <div class="divider-vertical my-15" style="height: 115px"></div>
      </div>
    </div>
    <div class="tile-row">
      <div class="white-card">
        <h3 class="tile-content">
          Современные цифровые платформы, упрощающие бизнес-процессы, часто
          увеличивают риски для предпринимателей.
        </h3>
        <a
          class="source-link no-uppercase"
          href="https://forbes.kz/articles/tendentsii_kiberbezopasnosti_top-5_ugroz_dlya_malogo_i_srednego_biznesa_v_2023_godu"
          target="_blank"
          rel="noopener noreferrer"
        >
          Источник:
          <span class="underline">forbes.kz</span>
        </a>
      </div>
      <div class="black-card">
        <h3 class="card-title">60%</h3>
        <p class="card-description">
          Предприятий сталкиваются с киберугрозами, связанными с использованием
          облачных технологий и сторонних сервисов.
        </p>
      </div>
    </div>
    <div class="tile-before px-5">
      <div class="divider-vertical my-15" style="height: 115px"></div>
      <p class="description">
        В результате, мы наблюдаем явление, названное нашими экспертами
        «Цифровой крест»
      </p>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "ProblemBlock2",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const image = this.$refs.image;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: image,
        scroller: document.querySelector(".main-container"),
        start: "top 90%",
        end: "top 40%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      image,
      { scale: 0.5, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.content {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 48px;
  font-weight: 550;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.tile {
  max-width: 1000px;
  width: 100%;
  border: 2px solid #333;
  border-bottom: none;
  border-radius: 60px 60px 0 0;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  background-image: url("@/assets/images/problem-block2-bacground.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center calc(100% - 80px);
}

.description {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.tile-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 73px;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.tile-row {
  display: flex;
  align-items: stretch;
  border: 2px solid #333;
  border-radius: 40px;
  max-width: 1200px;
  width: 100%;
  min-height: 50px;
  height: 100%;
}

.white-card {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 20px;
  padding: 34px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid #333;
  color: black;
}

.black-card {
  flex-grow: 1;
  padding: 34px;
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("@/assets/images/image-backgrount-problen-tile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tile-content {
  font-size: 40px;
  font-weight: 550;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.source-link {
  opacity: 0.5;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.card-title {
  font-size: 120px;
  font-weight: 550;
  line-height: 144px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card-description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.tile-before {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 2px solid;
  border-right: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 33%,
    rgba(122, 122, 122, 0) 100%
  );
  border-image-slice: 1;
}
</style>
