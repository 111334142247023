<template>
  <section class="wrapper" ref="blockSection" id="block-16">
    <div
      class="row d-flex flex-row align-items-center justify-content-center flex-wrap"
    >
      <div
        class="card-wrapper"
        v-for="card in getCards"
        :key="card.id"
        :style="`width: ${card.width}%`"
        @click="openModal(card.id)"
      >
        <div
          class="card"
          ref="card"
          :style="`
            width: 100%;
            background-image: url('/images/${card.backgroundTail}');
          `"
        >
          <span class="card-title">{{ card.title }}</span>
          <div class="card-description">
            {{ card.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="divider-vertical my-15" style="height: 108px"></div>
  </section>
</template>

<script type="js">
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { mapGetters } from "vuex";

export default {
  name: "BlockSection16",
  methods: {
    openModal(id) {
      this.$emit("showProblemModal", id);
    },
  },
  computed: {
    ...mapGetters(["getCards"]),
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 0%",
        end: "top -10%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelectorAll(".card"),
      { y: "100%" },
      { y: "0%", duration: 1 },
      "=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.row {
  max-width: 1440px;
  width: 100%;
}

.card-wrapper {
  height: 370px;
  overflow: hidden;
  margin: 12px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border: 2px solid #37363a;
  padding: 25px;
  border-radius: 30px;
  background-size: cover;
  height: 370px;
  width: 100%;
  cursor: pointer;
  opacity: 0.8;
}

.card:hover {
  opacity: 1;
}

.card-title {
  border: 1px solid #ffffff;
  padding: 8px 20px;
  border-radius: 24px;
}

.card-description {
  font-size: 36px;
  font-weight: 550;
  line-height: 43px;
  text-align: left;
  text-transform: uppercase;
}

@media (max-width: 1300px) {
  .card-description {
    font-size: 28px;
    line-height: 34px;
  }
}
</style>
