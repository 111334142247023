<template>
  <section class="wrapper" id="block-7">
    <img
      src="@/assets/images/background-about-gts-block-7.png"
      alt="background"
      class="background"
    />
    <div class="content-block">
      <div class="divider-horizontal w-100"></div>
      <div class="card">
        <div class="left-side">
          <h2 class="card-title">
            <img
              src="@/assets/icons/icon-green-snow.png"
              alt="green-icon"
              class="icon-green"
            />
            задача<br />
            консорциума<br />
            онтосеть
          </h2>
        </div>
        <div class="right-side">
          <p class="text no-uppercase">
            В условиях, когда рынок требует более эффективных технологий, задача
            консорциума «ОНТОСЕТЬ» заключается в том, чтобы обновить ключевые
            достижения «BAIKONUR» и «ОБЬ», учитывая современные требования к
            критической информационной инфраструктуре (КИИ) и применяя новый
            технологический уровень.
          </p>
          <p class="text no-uppercase mt-2">
            Если вчера такой масштабный проект казался невозможным из-за
            монополии крупных интернет-корпораций из США, то сегодня, после их
            ухода из России, у нас появляется возможность создать новый цифровой
            мир.
          </p>
        </div>
      </div>
      <div class="divider-horizontal w-100"></div>
      <h2 class="title">
        Начни формировать устойчивое будущее<br />
        с технологией “GTS”
      </h2>
      <h3 class="sub-title">
        Вступая в консорциум онтосеть вы становитесь фронтиром, который
        формирует цифровую среду взаимодействия нового поколения на основе
        открытых стандартов, столько необходимых в условиях тотальной
        конкуренции и недоверия.
      </h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutGtsBlock7",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -17%;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: -17%;
}

.background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
}

.content-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1440px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;
  align-items: start;
  max-width: 1200px;
  width: 100%;
}

.left-side {
  width: 50%;
  text-align: left;
  padding-right: 20px;
}

.card-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 53px;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.icon-green {
  width: 43px;
  height: 43px;
  margin-right: 5px;
}

.right-side {
  width: 50%;
  text-align: right;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 75px;
  letter-spacing: -0.2879px;
  text-align: center;

  margin-bottom: 40px;
  max-width: 1200px;
  width: 100%;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
  max-width: 900px;
  width: 100%;
}
</style>
