<template>
  <section class="wrapper" ref="blockSection" id="block-2">
    <img class="hero-img" src="@/assets/images/image-block-2.png" alt="img" />
    <div class="hero">
      <h2 class="hero-title my-15">
        Создай новое <br />
        поле игры!
      </h2>
      <p class="description">
        Вступайте в консорциум Онтосеть, <br />
        чтобы создать стратегическое <br />
        преимущество и гарантировать <br />
        рентабельность вашего бизнеса завтра!
      </p>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection2",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 70%",
        end: "top 0%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".hero-img"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 }
    );

    timeline.fromTo(
      blockSection.querySelector(".hero-title"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".description"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin-top: 250px;
  padding-bottom: 100px;
}

.hero-img {
  opacity: 0;
}

.hero {
  position: absolute;
  top: 60%;
}

.hero-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
}

.description {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
}

@media (max-width: 1000px) {
  .hero-title {
    font-size: 45px;
  }

  .description {
    font-size: 28px;
  }
}
</style>
