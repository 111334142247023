<template>
  <section class="wrapper" id="block-8">
    <div class="title-block">
      <img
        src="@/assets/images/image-problem-block-8-title-background.png"
        alt="background"
      />
      <h2 class="title">
        К сожалению, рынок IT стал прибыльным бизнесом, ориентированным на
        процесс, а не на результат, порождая больше проблем, чем способен
        решить.
      </h2>
    </div>
    <div class="tile-row">
      <div class="white-card">
        <h3 class="tile-content">
          Вероятность успеха ИТ-проекта по статистике The Standish Group
        </h3>
        <a
          class="source-link no-uppercase"
          href="https://tpprf.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          Источник:
          <span class="underline">tpprf.ru</span>
        </a>
      </div>
      <div class="black-card">
        <img
          src="@/assets/images/blur-white-background-problem-tile-8.png"
          alt="background"
          class="background-image"
        />
        <h3 class="card-title">31%</h3>
        <p class="card-description my-5">
          Вкладывая большие деньги в развитие ИТ-<br />предприятия часто
          получают...
        </p>
        <h3 class="tile-title">
          чемодан без ручки: работает<br />
          плохо, а выбросить жалко.
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock8",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.title-block {
  position: relative;
  z-index: 1;
  max-width: 1300px;
  width: 100%;
  margin-bottom: 0;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1300px;
  width: 100%;
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  z-index: 2;
}

.tile-row {
  display: flex;
  align-items: stretch;
  border: 2px solid #333;
  border-radius: 40px;
  max-width: 1200px;
  width: 100%;
  min-height: 50px;
  height: 100%;
}

.white-card {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 20px;
  padding: 34px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  border: 1px solid #333;
  color: black;
}

.black-card {
  position: relative;
  flex-grow: 1;
  padding: 34px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("@/assets/images/image-tile-background-problen-block-8.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 3%;
  left: 0;
  z-index: -1;
}

.tile-title {
  font-size: 33px;
  font-weight: 550;
  line-height: 40px;
  letter-spacing: -0.1981px;
  text-align: center;
}

.tile-content {
  font-size: 40px;
  font-weight: 550;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.source-link {
  opacity: 0.5;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.card-title {
  font-size: 120px;
  font-weight: 550;
  line-height: 144px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card-description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
