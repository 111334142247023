<template>
  <section class="wrapper" id="block-23" ref="content">
    <div class="divider-horizontal my-0" style="width: 100%"></div>
    <img
      src="@/assets/images/image1-problem-block-23.png"
      alt="internet"
      style="padding-right: 350px"
      class="animation-2"
    />
    <div class="divider-horizontal my-0" style="width: 100%"></div>
    <div class="d-flex w-100">
      <div class="left-side">
        <div class="square animation-1">Ваш<br />Бизнес</div>
      </div>
      <div class="right-side">
        Цифровая<br />
        Инфраструктура
      </div>
    </div>
    <div class="divider-horizontal my-0" style="width: 100%"></div>
    <img
      src="@/assets/images/image3-problem-block-23.png"
      alt="bag"
      style="padding-right: 400px"
      class="animation-2"
    />
    <div class="divider-horizontal my-0" style="width: 100%"></div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "ProblemBlock23",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const content = this.$refs.content;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: content,
        scroller: document.querySelector(".main-container"),
        start: "top 90%",
        end: "top 10%",
        scrub: 0.5,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });
    timeline.fromTo(
      content.querySelectorAll(".animation-1"),
      { x: "-250px", autoAlpha: 0 },
      {
        x: 0,
        autoAlpha: 1,
        duration: 1,
        ease: "linear",
      },
      "-=0"
    );

    timeline.fromTo(
      content.querySelectorAll(".animation-2"),
      { x: "-100px", autoAlpha: 0 },
      {
        x: "250px",
        autoAlpha: 1,
        duration: 1,
        ease: "linear",
      },
      "-=0.5"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.left-side {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 50px;
}

.square {
  width: 105px;
  height: 105px;
  background-color: #fff;
  color: black;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(8deg);
}

.right-side {
  width: 50%;
  text-align: left;
  background-color: #eb4a27;
  padding: 25px 40px;
  font-size: 43px;
  font-style: italic;
  font-weight: 550;
  line-height: 52px;
  letter-spacing: -0.16968px;
}
</style>
